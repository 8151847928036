import styled from 'styled-components'
import { motion } from 'framer-motion'

import devices from '../../styles/devices'

import Text from '../Text'

export const Content = styled.section`
  height: calc(100vh - 80px);
  margin-top: 80px;
  display: grid;
  place-items: center;
  padding-bottom: 30px;
  margin-bottom: 100px;
  gap: 8.6%;
  align-content: center;

  @media ${devices.mobile} {
    height: 100vh;
    place-items: unset;
    margin: 0;
    padding: 80px 0 40px;
    gap: 0;
  }
`

export const MotionIframeContainer = styled(motion.div)`
  aspect-ratio: 2 / 1;
  min-width: 40vw;
`

export const Flex = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 50px;
  align-items: center;

  ${(props) =>
    props.column &&
    `
    flex-direction: column;
  `}

  @media ${devices.mobile} {
    flex-direction: column;
    align-items: center;
    margin: 20px 0;
  }
`

export const StyledText = styled(Text)`
  font-weight: 300;
  font-size: 2.7rem;
  max-width: 900px;
  text-align: center;

  @media ${devices.mobile} {
    font-size: 1.8rem;
    width: 100%;
    text-align: center;
  }
`

export const AsideText = styled(Text)`
  max-width: 800px;
  text-align: center;
  font-size: 20px;

  @media ${devices.mobile} {
    width: 100%;
    font-size: var(--default-font-size);
    font-size: 16px;
  }
`

export const BottomTextContainer = styled(motion.div)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const BottomText = styled(Text)`
  max-width: 520px;
  text-align: center;
  font-size: var(--default-font-size);

  @media ${devices.mobile} {
    flex-direction: column;
    width: 100%;
  }
`

export const Emoji = styled(motion.div)`
  display: inline-block;
  width: 55px;
`

export const Industry = styled(motion.div)`
  display: inline-block;
  margin-right: 10px;
  font-weight: 600;
  display: inline-block;
  width: 320px;
  white-space: nowrap;
  text-align: left;

  @media ${devices.mobile} {
    width: 100%;
    text-align: center;
  }
`

export const IconContainer = styled(motion.div)`
  height: 25px;
  cursor: pointer;
  transition: 0.2s all ease;

  :hover {
    opacity: 1;
  }

  svg {
    height: 100%;
  }
`

export const TagContainer = styled.div`
  width: 920px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 25px;
`
