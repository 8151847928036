import * as S from './styles'

const Tag = ({ icon, description }) => (
  <S.Tag>
    <S.Icon>{icon}</S.Icon>
    <S.TagDescription>{description}</S.TagDescription>
  </S.Tag>
)

export default Tag
