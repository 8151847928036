export default function Arrow({ fill }) {
  return (
    <svg
      width="46"
      height="57"
      viewBox="0 0 46 57"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M41.1051 30.9618L26.5522 44.2824V0.606445H19.7802V44.2824L5.22729 30.9618L0.486328 35.3003L23.1663 56.0522L45.8463 35.3003L41.1051 30.9618Z"
        fill={fill}
      />
    </svg>
  )
}
