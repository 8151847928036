import styled from 'styled-components'
import devices from '../../styles/devices'

import Text from '../Text'

export const Container = styled.section`
  text-align: center;
  padding-bottom: 150px;

  @media ${devices.mobile} {
    padding-bottom: 80px;
  }
`

export const Card = styled.div`
  width: calc(100% / 4);
  height: fit-content;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background: ${(props) => props.theme.accent};
  padding: 25px;
  border-radius: var(--border-radius);
  gap: 30px;
  margin: 80px 0 0;
  box-shadow: ${(props) => props.theme.boxShadow};

  @media ${devices.mobile} {
    width: 85%;
  }
`

export const CardTitle = styled(Text)`
  font-size: 28px;
`

export const CardPrice = styled(Text)`
  font-size: 72px;

  em {
    font-style: normal;
    opacity: 0.7;
    margin-right: 5px;
    display: inline-block;
    transform: translateY(-25px);
    font-size: 36px;
  }
`

export const List = styled.ul`
  text-align: left;
  margin: auto;
  padding: 0;
`

export const Member = styled.span`
  font-size: 16px;
  opacity: 0.7;
`

export const Flex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 20px;

  @media ${devices.mobile} {
    flex-direction: column;
    align-items: center;
    gap: 0;
  }
`
