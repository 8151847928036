import { useEffect } from 'react'
import mixpanel from 'mixpanel-browser'
import { useInView } from 'react-intersection-observer'

import { observerOptions } from '../../config'

const Observer = ({ section, children }) => {
  const { ref, inView } = useInView(observerOptions)

  useEffect(() => {
    if (inView && process.env.NODE_ENV !== 'development') {
      mixpanel.track('page_viewed', {
        section,
      })
    }
  }, [inView, section])

  return <div ref={ref}>{children}</div>
}

export default Observer
