import styled from 'styled-components'
import Text from '../Text'
import devices from '../../styles/devices'

export const Content = styled.section`
  height: 100%;
  padding-bottom: 150px;

  @media ${devices.mobile} {
    padding-bottom: 0px;
  }
`

export const Flex = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  ${(props) =>
    props.reverse &&
    `
    flex-direction: row-reverse;
  `}

  @media ${devices.mobile} {
    flex-direction: column;
    gap: 50px;
    padding-bottom: 100px;
  }
`

export const FlexChildren = styled.div`
  width: 50%;
  display: grid;
  place-items: center;
  height: 100%;

  @media ${devices.mobile} {
    width: 100%;
    place-items: unset;
  }
`

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1.2;
  width: 80%;
  gap: 50px;

  @media ${devices.mobile} {
    width: 100%;
  }
`

export const AssetContainer = styled.div`
  display: grid;
  place-items: center;
  flex: 1.5;
  width: 100%;

  @media ${devices.mobile} {
    place-items: unset;
  }
`

export const Image = styled.img`
  max-height: 80vh;
  ${(props) => !props.originalsize && `width: 36vw;`}

  @media ${devices.mobile} {
    width: 100%;
  }
`

export const Heading = styled(Text)`
  font-weight: 600;
  font-size: 46px;
  text-align: left;

  @media ${devices.mobile} {
    font-size: 2rem;
  }
`

export const Description = styled(Text)`
  font-weight: 300;
  font-size: var(--default-font-size);
  text-align: left;
  line-height: 1.5;
`

export const CTAContainer = styled.div`
  width: 50%;

  @media ${devices.mobile} {
    width: 100%;
    text-align: center;
  }
`

export const BottomText = styled.div`
  margin-bottom: 100px;

  @media ${devices.mobile} {
    margin-bottom: 50px;
  }
`

export const SvgImage = styled.div`
  ${(props) =>
    props.halfsize &&
    `
  width: 65%;
`}
  @media ${devices.mobile} {
    width: 100%;
  }
`
