import { createContext, useContext, useState } from 'react'

const CalendlyContext = createContext()

export function CalendlyContextProvider({ children }) {
  const [showCalendly, setShowCalendly] = useState(false)

  const value = {
    showCalendly,
    setShowCalendly,
  }

  return (
    <CalendlyContext.Provider value={value}>
      {children}
    </CalendlyContext.Provider>
  )
}

export function useCalendly() {
  return useContext(CalendlyContext)
}
