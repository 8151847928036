import styled from 'styled-components'
import devices from '../../styles/devices'

function getSliderThumbColor(color) {
  switch (color) {
    case 'blue':
      return 'var(--slingshot-aqua)'
    case 'red':
      return 'var(--base-red)'
    case 'yellow':
      return 'var(--base-yellow)'
    case 'green':
      return 'var(--base-green)'
    default:
      return 'var(--slingshot-white)'
  }
}

export const Content = styled.div`
  display: flex;
  gap: 40px;
  align-items: center;
  margin-bottom: 150px;

  @media ${devices.mobile} {
    flex-direction: column;
    gap: 100px;
    margin-bottom: 100px;
  }
`

export const Calculator = styled.div`
  padding: 40px;
  border-radius: var(--border-radius);
  background: ${(props) => props.theme.calculatorBackground};
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 50%;
  font-size: 18px;

  @media ${devices.mobile} {
    width: 100%;
    padding: 20px;
  }
`

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: center;
  margin-bottom: 100px;

  @media ${devices.mobile} {
    margin-bottom: 50px;
  }
`

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media ${devices.mobile} {
    gap: 10px;
  }
`

export const DescriptionContainer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-between;

  @media ${devices.mobile} {
    gap: 15px;
  }
`

export const Description = styled.p`
  line-height: 1.4;
  width: 100%;
`

export const Total = styled.div`
  display: grid;
  place-items: center;
  background: ${(props) => props.theme.calculatorAccent};
  height: 42px;
  width: 120px;
  border-radius: var(--border-radius);
`

export const Label = styled.label`
  font-size: 14px;
`
export const SliderLabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Slider = styled.input`
  --slider-thumb-color: ${(props) => getSliderThumbColor(props.color)};
  --height: 14px;

  @media ${devices.mobile} {
    --height: 18px;
  }

  -webkit-appearance: none;
  overflow: hidden;
  height: var(--height);
  width: 100%;
  cursor: pointer;
  border-radius: 0;

  ::-webkit-slider-runnable-track {
    background: ${(props) => props.theme.calculatorAccent};
  }

  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 20px;
    height: var(--height);
    opacity: 0.8;
    transition: 0.2s all ease;
    background: ${(props) => props.theme.calculatorThumb};
    box-shadow: -100vw 0 0 100vw var(--slider-thumb-color);
    border: 2px solid ${(props) => props.theme.calculatorThumbBorder};
  }

  ::-moz-range-track {
    height: 40px;
    background: ${(props) => props.theme.calculatorAccent};
  }

  ::-moz-range-thumb {
    background: ${(props) => props.theme.calculatorThumb};
    height: var(--height);
    opacity: 0.8;
    transition: 0.2s all ease;
    width: 20px;
    border: 2px solid ${(props) => props.theme.calculatorThumbBorder};
    border-radius: 0 !important;
    box-shadow: -100vw 0 0 100vw var(--slider-thumb-color);
    box-sizing: border-box;
  }

  ::-ms-fill-lower {
    background: var(--slider-thumb-color);
  }

  ::-ms-ticks-after {
    display: none;
  }

  ::-ms-ticks-before {
    display: none;
  }

  ::-ms-tooltip {
    display: none;
  }

  :active {
    ::-webkit-slider-thumb {
      border: 10px solid ${(props) => props.theme.calculatorThumbBorder};
      opacity: 1;
    }
    ::-moz-range-thumb {
      border: 10px solid ${(props) => props.theme.calculatorThumbBorder};
      opacity: 1;
    }
  }
`

export const TotalSpending = styled.p`
  font-size: 36px;
  font-weight: bold;

  em {
    font-size: 16px;
    font-style: normal;
    opacity: 0.5;
  }
`

export const Result = styled.div`
  text-align: center;
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
  font-size: 18px;

  @media ${devices.mobile} {
    width: 100%;
    padding: 20px;
  }
`
