import styled from 'styled-components'
import devices from '../../styles/devices'

function getListItemColor(color) {
  switch (color) {
    case 'red':
      return 'var(--base-red-77)'
    case 'yellow':
      return 'var(--base-yellow-74)'
    case 'green':
      return 'var(--base-green-59)'
    default:
      return 'var(--slingshot-white)'
  }
}

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 20px;
  margin: 100px 0 0;

  @media ${devices.mobile} {
    grid-template-columns: 1fr;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 80px;
    margin-bottom: 100px;
  }
`

export const Step = styled.div`
  display: grid;
  grid-template-columns: 100px 1fr;
  grid-template-rows: 1fr 50px;
  gap: 20px;
  justify-content: center;
  justify-items: center;
  width: 75%;
  margin: 0 auto;

  @media ${devices.mobile} {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: fit-content;
  }
`

export const StepContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
`

export const Title = styled.p`
  font-size: 38px;
  font-weight: 600;
`

export const Emoji = styled.div`
  font-size: 64px;
  display: flex;
  flex-direction: column;
  position: relative;

  ::after {
    content: '${(props) => props.step}';
    font-size: 4rem;
    margin: 0 auto;
    opacity: 0.3;
  }
`

export const Description = styled.p`
  line-height: 1.6;
  font-size: 18px;
`

export const List = styled.ul`
  background: ${(props) => props.theme.ghostBackground};
  height: 190px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 30px;
  border-radius: var(--border-radius);
`

export const ListItem = styled.li`
  color: ${(props) => getListItemColor(props.color)};
  font-weight: 600;
`
