import * as S from './styles'

import Observer from '../Observer'
import SectionTitle from '../SectionTitle'
import CallToAction from '../CallToAction'
import Text from '../Text'

import { showUpMotion } from '../../config'

import { Container } from '../../styles/globalStyles'

const showUpVariant = showUpMotion()

const VideoPreview = ({ embedLink, showExtra }) => (
  <Container>
    <Observer section="video_preview">
      <S.Container>
        <S.VideoContainer>
          <S.MotionIframeContainer
            variants={showUpVariant}
            initial="hidden"
            animate="visible"
          >
            <iframe
              width="100%"
              height="100%"
              src={embedLink}
              title="YouTube video player"
              frameBorder="0"
              allowFullScreen="allowFullScreen"
            />
          </S.MotionIframeContainer>
        </S.VideoContainer>
        {showExtra && (
          <>
            <S.Extra>
              <Text>Convinced? Contact us below!</Text>
              <CallToAction />
            </S.Extra>
            <S.Spacer>
              <SectionTitle>RMS Intermodal Use Case</SectionTitle>
            </S.Spacer>
          </>
        )}
      </S.Container>
    </Observer>
  </Container>
)

export default VideoPreview
