import { useState } from 'react'

import { Container } from '../../styles/globalStyles'

import Observer from '../Observer'
import SectionTitle from '../SectionTitle'
import Text from '../Text'

import * as S from './styles'

const items = [
  {
    id: 'phone-hours',
    description: 'Hours per week in phone meetings with suppliers',
    total: 8,
    slider: {
      min: 1,
      max: 30,
      step: 1,
      suffix: null,
      prefix: null,
      color: 'blue',
    },
  },
  {
    id: 'email-hours',
    description: 'Hours per week in email communications with vendors',
    total: 10,
    slider: {
      min: 1,
      max: 30,
      step: 1,
      suffix: null,
      prefix: null,
      color: 'red',
    },
  },
  {
    id: 'employee-count',
    description: 'Employees with similar workflow',
    total: 2,
    slider: {
      min: 1,
      max: 50,
      step: 1,
      suffix: '+',
      prefix: null,
      color: 'yellow',
    },
  },
  {
    id: 'average-hourly-rate',
    description: 'Average hourly rate for team member performing these actions',
    total: 45,
    slider: {
      min: 1,
      max: 150,
      step: 1,
      suffix: null,
      prefix: '$',
      color: 'green',
    },
  },
]

const SuffixPrefixContainer = ({ item, children }) => {
  return (
    <>
      {item.slider.prefix}
      {children}
      {item.slider.suffix}
    </>
  )
}

let initialState = {}
items.forEach((item) => (initialState[`slider-${item.id}`] = item.total))

function getTotalMoneyWaste(state) {
  return Number(
    (state[`slider-phone-hours`] + state[`slider-email-hours`]) *
      state[`slider-employee-count`] *
      state[`slider-average-hourly-rate`]
  ).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  })
}

function getTotalTimeWaste(state) {
  return `${state[`slider-phone-hours`] + state[`slider-email-hours`]} hours`
}

const Calculator = () => {
  const [state, setState] = useState(initialState)

  function handleChange(e) {
    const { value, name } = e.target
    setState((prevState) => ({ ...prevState, [name]: Number(value) }))
  }

  return (
    <Container>
      <Observer section="calculator">
        <S.TitleContainer>
          <SectionTitle>Time Waster 2000</SectionTitle>
          <Text>
            How much money do you shell out each week coordinating projects the
            old-fashioned way?
          </Text>
        </S.TitleContainer>

        <S.Content>
          <S.Calculator>
            {items.map((item) => (
              <S.Item key={item.id}>
                <S.DescriptionContainer>
                  <S.Description>{item.description}</S.Description>

                  <S.Total>
                    <SuffixPrefixContainer item={item}>
                      {state[`slider-${item.id}`]}
                    </SuffixPrefixContainer>
                  </S.Total>
                </S.DescriptionContainer>

                <S.Slider
                  onChange={handleChange}
                  type="range"
                  name={`slider-${item.id}`}
                  min={item.slider.min}
                  max={item.slider.max}
                  step={item.slider.step}
                  defaultValue={state[`slider-${item.id}`]}
                  color={item.slider.color}
                />

                <S.SliderLabelContainer>
                  <S.Label>
                    <SuffixPrefixContainer item={item}>
                      {item.slider.min}
                    </SuffixPrefixContainer>
                  </S.Label>
                  <S.Label>
                    <SuffixPrefixContainer item={item}>
                      {item.slider.max}
                    </SuffixPrefixContainer>
                  </S.Label>
                </S.SliderLabelContainer>
              </S.Item>
            ))}
          </S.Calculator>

          <S.Result>
            <Text>You’re wasting roughly</Text>
            <S.TotalSpending>
              {getTotalMoneyWaste(state)}
              <em>/week</em>
            </S.TotalSpending>
            <Text>in</Text>
            <S.TotalSpending>{getTotalTimeWaste(state)}</S.TotalSpending>
            <Text>
              Versus <strong>investing for</strong>
            </Text>
            <S.TotalSpending>FREE</S.TotalSpending>
            <Text>with slingshot!</Text>
          </S.Result>
        </S.Content>
      </Observer>
    </Container>
  )
}

export default Calculator
