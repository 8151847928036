import { decode } from 'base-64'
import _ from 'lodash'

import brandon from './images/brandon.png'
import adam from './images/adam.png'
import andre from './images/andre.png'
import janselle from './images/janselle.png'
import sam from './images/sam.png'
// import osman from './images/osman.png'
// import slava from './images/slava.png'

export const headerHeight = 100

export const teamList = [
  { name: 'Brandon Stewart', image: brandon, role: 'CEO | Co-Founder' },
  { name: 'Adam Gray', image: adam, role: 'COO | Co-Founder' },
  { name: 'Janselle Justo', image: janselle, role: 'Research & Design' },
  { name: 'Andre Benatti', image: andre, role: 'Engineer | Frontend' },
  { name: 'Samuel Timbó', image: sam, role: 'Engineer | Full Stack' },
]

export function fill(isLightMode) {
  return isLightMode ? 'var(--slingshot-aqua)' : 'var(--slingshot-white)'
}

export const maxWidth = '1296px'

export const headingSentence = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.1,
      staggerChildren: 0.2,
    },
  },
}

export const headingLetter = {
  hidden: { opacity: 0, y: 15 },
  visible: {
    opacity: 1,
    y: 0,
  },
}

export const animatedWord = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      delay: 1.75,
    },
  },
}

export const showUpMotion = (delay = 2.3) => ({
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay,
    },
  },
})

export const observerOptions = {
  triggerOnce: true,
  rootMargin: '-100px 0%',
}

export const queryStringToObject = (queryString) =>
  JSON.parse(
    '{"' + queryString.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
    function (key, value) {
      return key === '' ? value : decodeURIComponent(value)
    }
  )

export function getUrlParam(params) {
  const hash = params.get('q')

  try {
    const decodedHash = decode(hash)

    if (_.isNil(hash)) {
      return {}
    }

    const queryObject = queryStringToObject(decodedHash)

    return queryObject
  } catch (error) {
    return {}
  }
}

export const queryStringValues = ['s']

export const templates = {
  auditing: {
    image: 'audits',
    operations: 'auditing',
    optimizeA: 'outdated auditing reports',
    optimizeB: 'safety',
    problemTitle: 'auditing',
    problemDescriptionA: `quickly collecting the relevant auditing data`,
    problemDescriptionB: `verifying how well you're really doing.`,
    solutionTitle: 'auditing',
    solutionDescriptionA: 'compliance',
    solutionDescriptionB: 'make fast decisions.',
    discuss: 'auditing',
    discussResolution: 'auditing process',
  },
  billing: {
    image: 'billing',
    operations: 'financial',
    optimizeA: 'tedious billing templates',
    optimizeB: 'efficiency',
    problemTitle: 'billing',
    problemDescriptionA: `automating the relevant billing data`,
    problemDescriptionB: `sending dynamic and accurate invoices faster.`,
    solutionTitle: 'billing',
    solutionDescriptionA: 'billing',
    solutionDescriptionB: 'make confident decisions.',
    discuss: 'billing',
    discussResolution: 'billing process',
  },
}

export const sectors = {
  default: {
    industries: [
      'Vendors',
      'Suppliers',
      'Businesses',
      'Contractors',
      'Customers',
    ],
    emojis: ['🚛', '📦', '🏘', '🕵🏼‍♀️', '🙋‍♀️'],
  },
  agriculture: {
    industries: ['Agriculture', 'Farming', 'Fishery', 'Ranching'],
    emojis: ['🌱', '🐮', '🐟', '🐴'],
  },
  finance: {
    industries: ['Investment', 'Capital Markets', 'Banking', 'Private Equity'],
    emojis: ['💰', '💵', '🏦', '⌚️'],
  },
  manufacturing: {
    industries: ['Manufacturing', 'Jewelry', 'Nanotechnology'],
    emojis: ['🏭', '💎', '🔬'],
  },
  mining: {
    industries: ['Mining & Metals', 'Oil & Energy'],
    emojis: ['⛏', '🛢'],
  },
  administration: {
    industries: ['Law Enforcement', 'Legislative Office', 'Security'],
    emojis: ['🚨', '📚', '🕵🏼‍♀️'],
  },
  retails: {
    industries: ['Health', 'Travel', 'Retail', 'Wine & Spirits'],
    emojis: ['🛏', '✈️', '🛍', '🥂'],
  },
  service: {
    industries: ['Animation', 'Broadcast', 'Health Care', 'Pharmaceuticals'],
    emojis: ['🎥', '🎙', '🏥', '💊'],
  },
  technology: {
    industries: ['Hardware', 'Space', 'IT Services'],
    emojis: ['💻', '📡', '🧑🏾‍💻'],
  },
  transportation: {
    industries: [
      'Airlines',
      'Logistics',
      'Offshoring',
      'Delivery',
      'Transportation',
    ],
    emojis: ['✈️', '📈', '🛳', '📦', '🚛'],
  },
  other: {
    industries: ['Education', 'Philanthropy', 'Research'],
    emojis: ['🎓', '📖', '🔬'],
  },
  warehousing: {
    industries: ['Warehousing'],
    emojis: ['📦'],
  },
  wholesale: {
    industries: ['Wholesale'],
    emojis: ['📦'],
  },
  construction: {
    industries: ['Construction'],
    emojis: ['🏗'],
  },
}

export function getLottieOptions(animationData) {
  return {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }
}
