export default function Logo({ fill }) {
  return (
    <svg
      width="162"
      height="38"
      viewBox="0 0 162 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M92.0672 37.0012H82.8689C82.4936 37.0012 82.1337 36.8522 81.8683 36.5868C81.6029 36.3214 81.4538 35.9614 81.4538 35.5861C81.4538 35.2108 81.6029 34.8509 81.8683 34.5855C82.1337 34.3201 82.4936 34.171 82.8689 34.171H91.2889V31.5531H82.0906C81.8247 31.5519 81.5618 31.496 81.3183 31.3889C81.0749 31.2818 80.8561 31.1257 80.6755 30.9304C80.4715 30.7268 80.311 30.4838 80.204 30.2162C80.0969 29.9485 80.0455 29.6619 80.0529 29.3738V19.5458C80.0397 19.2674 80.0888 18.9896 80.1965 18.7326C80.3042 18.4756 80.4678 18.2458 80.6755 18.06C81.0641 17.7103 81.5679 17.5163 82.0906 17.5151H92.0672C92.3923 17.5204 92.7118 17.6003 93.0011 17.7486C93.3192 17.9106 93.588 18.155 93.7794 18.4562C93.9888 18.7832 94.0971 19.1647 94.0908 19.5529V34.9918C94.1044 35.2702 94.0556 35.5482 93.9479 35.8053C93.8401 36.0624 93.6762 36.2921 93.4681 36.4777C93.0796 36.8273 92.5757 37.0213 92.053 37.0225L92.0672 37.0012ZM91.2818 28.737V20.7274C91.2818 20.6149 91.2371 20.5069 91.1575 20.4273C91.0778 20.3476 90.9698 20.3029 90.8572 20.3029H83.2864C83.1738 20.3029 83.0658 20.3476 82.9862 20.4273C82.9066 20.5069 82.8619 20.6149 82.8619 20.7274V28.3054C82.8619 28.418 82.9066 28.526 82.9862 28.6056C83.0658 28.6852 83.1738 28.7299 83.2864 28.7299L91.2818 28.737Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M133.805 28.7512H141.383C141.495 28.7512 141.603 28.7065 141.683 28.6268C141.762 28.5472 141.807 28.4392 141.807 28.3266V20.7275C141.807 20.6149 141.762 20.5069 141.683 20.4273C141.603 20.3477 141.495 20.3029 141.383 20.3029H133.805C133.693 20.3048 133.587 20.3503 133.509 20.4298C133.431 20.5092 133.387 20.6161 133.387 20.7275V28.3125C133.389 28.4226 133.434 28.5278 133.512 28.6057C133.589 28.6835 133.695 28.7281 133.805 28.7299V28.7512ZM142.586 31.5814H132.602C132.336 31.5811 132.073 31.5256 131.829 31.4184C131.585 31.3112 131.367 31.1547 131.187 30.9587C130.983 30.7551 130.822 30.5121 130.715 30.2445C130.608 29.9769 130.557 29.6903 130.564 29.4021V19.5459C130.551 19.2675 130.6 18.9897 130.708 18.7326C130.815 18.4756 130.979 18.2458 131.187 18.06C131.573 17.7068 132.078 17.5123 132.602 17.5152H142.586C143.108 17.5163 143.612 17.7104 144.001 18.06C144.207 18.2471 144.369 18.4771 144.477 18.7338C144.584 18.9905 144.634 19.2677 144.623 19.5459V29.3738C144.631 29.662 144.579 29.9486 144.472 30.2162C144.365 30.4838 144.205 30.7269 144.001 30.9305C143.82 31.1258 143.601 31.2818 143.358 31.3889C143.114 31.4961 142.851 31.5519 142.586 31.5531V31.5814Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M149.442 31.5533C149.176 31.553 148.913 31.4976 148.669 31.3904C148.426 31.2832 148.207 31.1267 148.027 30.9307C147.823 30.7271 147.663 30.4841 147.556 30.2165C147.449 29.9488 147.397 29.6622 147.404 29.3741V11.7418C147.404 11.3665 147.554 11.0065 147.819 10.7411C148.084 10.4758 148.444 10.3267 148.82 10.3267C149.195 10.3267 149.555 10.4758 149.82 10.7411C150.086 11.0065 150.235 11.3665 150.235 11.7418V17.5154H157.254C157.629 17.5154 157.989 17.6645 158.254 17.9299C158.52 18.1953 158.669 18.5552 158.669 18.9305C158.669 19.3059 158.52 19.6658 158.254 19.9312C157.989 20.1966 157.629 20.3457 157.254 20.3457H150.235V28.3481C150.235 28.4607 150.279 28.5687 150.359 28.6483C150.439 28.7279 150.547 28.7727 150.659 28.7727H160.063C160.438 28.7727 160.798 28.9217 161.063 29.1871C161.329 29.4525 161.478 29.8125 161.478 30.1878C161.478 30.5631 161.329 30.923 161.063 31.1884C160.798 31.4538 160.438 31.6029 160.063 31.6029L149.442 31.5533Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M74.0245 20.3239H66.4395C66.3269 20.3239 66.2189 20.3686 66.1393 20.4483C66.0597 20.5279 66.0149 20.6358 66.0149 20.7484V30.1519C66.0149 30.5272 65.8659 30.8871 65.6005 31.1525C65.3351 31.4179 64.9751 31.567 64.5998 31.567C64.2245 31.567 63.8646 31.4179 63.5992 31.1525C63.3338 30.8871 63.1847 30.5272 63.1847 30.1519V19.5385C63.1715 19.2602 63.2206 18.9823 63.3283 18.7253C63.436 18.4683 63.5997 18.2385 63.8074 18.0527C64.1937 17.6995 64.699 17.5049 65.2225 17.5078H75.2061C75.7288 17.509 76.2327 17.703 76.6212 18.0527C76.8264 18.2401 76.988 18.4703 77.0943 18.7271C77.2007 18.9839 77.2493 19.2609 77.2368 19.5385V30.1519C77.2368 30.5272 77.0877 30.8871 76.8223 31.1525C76.5569 31.4179 76.197 31.567 75.8217 31.567C75.4463 31.567 75.0864 31.4179 74.821 31.1525C74.5557 30.8871 74.4065 30.5272 74.4065 30.1519V20.7272C74.4019 20.6253 74.3608 20.5285 74.2906 20.4544C74.2205 20.3803 74.126 20.334 74.0245 20.3239Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.3917 10.3408C53.767 10.3408 54.1269 10.4899 54.3923 10.7553C54.6577 11.0207 54.8068 11.3806 54.8068 11.7559V30.1524C54.8068 30.5277 54.6577 30.8876 54.3923 31.153C54.1269 31.4184 53.767 31.5675 53.3917 31.5675C53.0164 31.5675 52.6564 31.4184 52.391 31.153C52.1256 30.8876 51.9766 30.5277 51.9766 30.1524V11.7559C51.9766 11.3806 52.1256 11.0207 52.391 10.7553C52.6564 10.4899 53.0164 10.3408 53.3917 10.3408Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M108.9 31.5528H98.2867C97.9114 31.5528 97.5515 31.4037 97.2861 31.1383C97.0207 30.873 96.8716 30.513 96.8716 30.1377C96.8716 29.7624 97.0207 29.4025 97.2861 29.1371C97.5515 28.8717 97.9114 28.7226 98.2867 28.7226H107.74C107.843 28.7104 107.939 28.6604 108.008 28.582C108.078 28.5037 108.115 28.4026 108.115 28.2981V26.3877C108.115 26.2751 108.07 26.1671 107.99 26.0875C107.911 26.0079 107.803 25.9631 107.69 25.9631H98.9164C98.6507 25.9603 98.3883 25.9037 98.1451 25.7967C97.9019 25.6897 97.6829 25.5345 97.5013 25.3405C97.2964 25.1361 97.1355 24.8919 97.0284 24.623C96.9213 24.3541 96.8704 24.0661 96.8787 23.7768V19.5668C96.8655 19.2884 96.9146 19.0106 97.0223 18.7536C97.13 18.4966 97.2936 18.2668 97.5013 18.081C97.8899 17.7313 98.3937 17.5373 98.9164 17.5361H109.53C109.905 17.5361 110.265 17.6852 110.53 17.9506C110.796 18.216 110.945 18.5759 110.945 18.9512C110.945 19.3266 110.796 19.6865 110.53 19.9519C110.265 20.2173 109.905 20.3664 109.53 20.3664H100.126C100.014 20.3664 99.9058 20.4111 99.8262 20.4907C99.7466 20.5703 99.7018 20.6783 99.7018 20.7909V22.7508C99.7018 22.8634 99.7466 22.9714 99.8262 23.051C99.9058 23.1306 100.014 23.1754 100.126 23.1754H108.9C109.424 23.171 109.93 23.3658 110.315 23.7202C110.523 23.9057 110.687 24.1354 110.795 24.3925C110.903 24.6497 110.951 24.9276 110.938 25.206V29.4514C110.945 29.7395 110.894 30.0262 110.787 30.2938C110.68 30.5614 110.519 30.8044 110.315 31.008C110.128 31.1893 109.906 31.3309 109.663 31.4245C109.42 31.5181 109.16 31.5618 108.9 31.5528Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M124.536 20.3244H116.541V30.1524C116.541 30.5277 116.392 30.8876 116.126 31.153C115.861 31.4184 115.501 31.5675 115.126 31.5675C114.75 31.5675 114.39 31.4184 114.125 31.153C113.86 30.8876 113.71 30.5277 113.71 30.1524V11.7559C113.71 11.3806 113.86 11.0207 114.125 10.7553C114.39 10.4899 114.75 10.3408 115.126 10.3408C115.501 10.3408 115.861 10.4899 116.126 10.7553C116.392 11.0207 116.541 11.3806 116.541 11.7559V17.5296H125.739C126.064 17.5331 126.384 17.613 126.673 17.7631C126.991 17.9251 127.26 18.1694 127.451 18.4706C127.664 18.7962 127.772 19.1787 127.763 19.5673V30.1807C127.763 30.556 127.613 30.9159 127.348 31.1813C127.083 31.4467 126.723 31.5958 126.347 31.5958C125.972 31.5958 125.612 31.4467 125.347 31.1813C125.081 30.9159 124.932 30.556 124.932 30.1807V20.7277C124.927 20.6236 124.884 20.5249 124.811 20.4505C124.738 20.3762 124.64 20.3313 124.536 20.3244Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.002 17.5151C59.3773 17.5151 59.7373 17.6642 60.0026 17.9296C60.268 18.195 60.4171 18.5549 60.4171 18.9302V30.1662C60.4171 30.5416 60.268 30.9015 60.0026 31.1669C59.7373 31.4323 59.3773 31.5814 59.002 31.5814C58.6267 31.5814 58.2668 31.4323 58.0014 31.1669C57.736 30.9015 57.5869 30.5416 57.5869 30.1662V18.9161C57.5906 18.5433 57.7414 18.1869 58.0063 17.9246C58.2713 17.6623 58.6292 17.5151 59.002 17.5151Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.002 14.7057C59.2819 14.7057 59.5555 14.6227 59.7882 14.4672C60.0209 14.3117 60.2023 14.0907 60.3094 13.8321C60.4165 13.5736 60.4445 13.289 60.3899 13.0145C60.3353 12.74 60.2005 12.4879 60.0026 12.29C59.8047 12.092 59.5526 11.9573 59.2781 11.9027C59.0036 11.8481 58.7191 11.8761 58.4605 11.9832C58.2019 12.0903 57.9809 12.2717 57.8254 12.5044C57.6699 12.7371 57.5869 13.0107 57.5869 13.2906C57.5869 13.6659 57.736 14.0258 58.0014 14.2912C58.2668 14.5566 58.6267 14.7057 59.002 14.7057Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.1369 31.5528H36.5235C36.1482 31.5528 35.7883 31.4037 35.5229 31.1383C35.2575 30.873 35.1084 30.513 35.1084 30.1377C35.1084 29.7624 35.2575 29.4025 35.5229 29.1371C35.7883 28.8717 36.1482 28.7226 36.5235 28.7226H45.9765C46.0803 28.7104 46.1759 28.6604 46.2451 28.582C46.3143 28.5037 46.3522 28.4026 46.3515 28.2981V26.3877C46.3515 26.2751 46.3068 26.1671 46.2271 26.0875C46.1475 26.0079 46.0395 25.9631 45.9269 25.9631H37.1532C36.8875 25.9603 36.6252 25.9037 36.3819 25.7967C36.1387 25.6897 35.9197 25.5345 35.7381 25.3405C35.5332 25.1361 35.3723 24.8919 35.2652 24.623C35.1581 24.3541 35.1072 24.0661 35.1155 23.7768V19.5668C35.1023 19.2884 35.1514 19.0106 35.2591 18.7536C35.3668 18.4966 35.5304 18.2668 35.7381 18.081C36.1267 17.7313 36.6306 17.5373 37.1532 17.5361H47.7666C48.1419 17.5361 48.5018 17.6852 48.7672 17.9506C49.0326 18.216 49.1817 18.5759 49.1817 18.9512C49.1817 19.3266 49.0326 19.6865 48.7672 19.9519C48.5018 20.2173 48.1419 20.3664 47.7666 20.3664H38.3773C38.2647 20.3664 38.1567 20.4111 38.0771 20.4907C37.9975 20.5703 37.9528 20.6783 37.9528 20.7909V22.7508C37.9528 22.8634 37.9975 22.9714 38.0771 23.051C38.1567 23.1306 38.2647 23.1754 38.3773 23.1754H47.1369C47.6606 23.171 48.1664 23.3658 48.552 23.7202C48.7601 23.9057 48.924 24.1354 49.0317 24.3925C49.1395 24.6497 49.1883 24.9276 49.1746 25.206V29.4514C49.182 29.7395 49.1305 30.0262 49.0235 30.2938C48.9164 30.5614 48.756 30.8044 48.552 31.008C48.3648 31.1893 48.143 31.3309 47.8999 31.4245C47.6568 31.5181 47.3972 31.5618 47.1369 31.5528Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.7045 6.50553L13.6974 0.505468C13.5383 0.34566 13.3492 0.218849 13.1409 0.132321C12.9327 0.045794 12.7094 0.00125239 12.4839 0.00125239C12.2584 0.00125239 12.0351 0.045794 11.8269 0.132321C11.6187 0.218849 11.4296 0.34566 11.2705 0.505468L2.07223 9.7037C0.744359 11.0513 0 12.8673 0 14.7592C0 16.6511 0.744359 18.467 2.07223 19.8147C2.73397 20.4864 3.52259 21.02 4.39228 21.3844C5.26196 21.7488 6.19539 21.9367 7.13833 21.9373C8.0811 21.9355 9.01417 21.747 9.8837 21.3827C10.7532 21.0184 11.542 20.4855 12.2044 19.8147L28.8108 3.20832L32.2707 6.6612L23.0725 15.8594V28.8289H18.1904V15.7745L15.438 18.5269V29.8478C15.4398 30.3033 15.6216 30.7395 15.9436 31.0615C16.2657 31.3836 16.7019 31.5653 17.1573 31.5672H24.1055C24.5615 31.5672 24.9989 31.386 25.3213 31.0636C25.6438 30.7412 25.8249 30.3038 25.8249 29.8478V16.9774L34.917 7.87819C35.0768 7.71909 35.2036 7.53001 35.2901 7.32177C35.3767 7.11353 35.4212 6.89024 35.4212 6.66474C35.4212 6.43923 35.3767 6.21595 35.2901 6.00771C35.2036 5.79947 35.0768 5.61038 34.917 5.45128L30.0066 0.498386C29.6834 0.179068 29.2474 0 28.7931 0C28.3388 0 27.9028 0.179068 27.5796 0.498386L10.287 17.8972C9.51744 18.6682 8.48818 19.1248 7.40013 19.1779H7.15956C6.08317 19.1827 5.04186 18.7953 4.23027 18.0882L4.00386 17.8618C3.19482 17.0331 2.74191 15.9209 2.74191 14.7627C2.74191 13.6046 3.19482 12.4923 4.00386 11.6636L12.4662 3.18003L17.7446 8.45131L19.7045 6.50553Z"
        fill={fill}
      />
    </svg>
  )
}
