import styled from 'styled-components'
import Text from '../Text'

import devices from '../../styles/devices'

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 150px 0 50px;
`

export const QuoteContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 25px;
  margin-top: 50px;
`

export const Quote = styled.div`
  width: 33%;
  background: ${(props) => props.theme.accent};
  padding: 25px;
  border-radius: var(--border-radius);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media ${devices.mobile} {
    width: 100%;
  }
`

export const Description = styled(Text)`
  line-height: 1.4;
`

export const Author = styled(Text)`
  strong {
    font-size: 18px;
  }
`
