import { useEffect } from 'react'
import { Router } from '@reach/router'
import { ThemeProvider } from 'styled-components'

import GlobalStyles from './styles/globalStyles'
import { useTheme } from './context/themeContext'

import { Layout } from './components'

import { Landing, Team, Builder } from './pages'

const ScrollToTop = ({ children, location }) => {
  useEffect(() => window.scrollTo(0, 0), [location.pathname])
  return children
}

const Routes = () => {
  const { theme } = useTheme()

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Layout>
        <Router primary={false}>
          <ScrollToTop path="/">
            <Landing path="/" />
            <Team path="/credits" />
            <Builder path="/builder" />
          </ScrollToTop>
        </Router>
      </Layout>
    </ThemeProvider>
  )
}

export default Routes
