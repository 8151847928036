import {
  Team,
  Quotes,
  Press,
  IntrmodlOperations,
  VideoPreview,
} from '../../components'

const Creators = () => (
  <>
    <Team />
    <Quotes />
    <VideoPreview
      embedLink="https://www.youtube.com/embed/1do0x3VSw1s?controls=1"
      showExtra
    />
    <IntrmodlOperations />
    <Press />
  </>
)

export default Creators
