import * as S from './styles'

const Text = ({ children, ...props }) => <S.Text {...props}>{children}</S.Text>

export const Anchor = ({ children, ...props }) => (
  <S.Anchor {...props}>{children}</S.Anchor>
)

export const Link = ({ children, ...props }) => (
  <S.StyledLink {...props}>{children}</S.StyledLink>
)

export default Text
