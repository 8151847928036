import _ from 'lodash'

import Observer from '../Observer'
import SectionTitle from '../SectionTitle'
import Text from '../Text'
import { Container } from '../../styles/globalStyles'

import * as S from './styles'

import { showUpMotion } from '../../config'

const summaries = [
  {
    id: 0,
    title: 'How difficult is it to integrate a vendor?',
    hiddenContent: () => (
      <>
        <Text>
          It takes seconds. After you sign up, add a guest email, 
          and assign them a task - 
          they can access the app with {' '}
          <strong>no account needed!</strong>
        </Text>
      </>
    ),
  },
  {
    id: 1,
    title: 'Can I generate a report?',
    hiddenContent: () => (
      <>
        <Text>
          We currently do not have that feature but with this partnership, we
          can cater reports based on your needs and input.
        </Text>
      </>
    ),
  },
  {
    id: 2,
    title: 'What apps can slingshot replace?',
    hiddenContent: () => (
      <>
        <Text>
          Depends on your workflow! We built this app to mitigate unecessary
          calls (over the phone or video), back-and-forth emails that are lost
          in the inbox, and, most importantly, lessen the amount of
          spreadsheets.
        </Text>
      </>
    ),
  },
  {
    id: 3,
    title: 'What if a vendor does not want to sign up?',
    hiddenContent: () => (
      <>
        <Text>
          We have a mechanism to send an automatic email, where they can press a
          button to respond to a status. That said, it would be easier for them
          to use the app, and see what total tasks and status they either have
          to do, or what they are waiting on.
        </Text>
      </>
    ),
  },
  // {
  //   id: 4,
  //   title: 'Do vendors pay?',
  //   hiddenContent: () => (
  //     <>
  //       <Text>
  //         No. Vendors do not pay. If you invite a vendor, they do not pay. A
  //         vendor would only pay if they invite different businesses they work
  //         with, and THEY want to manage those relationships as well
  //       </Text>
  //     </>
  //   ),
  // },
  {
    id: 5,
    title: 'Do I need to install the app?',
    hiddenContent: () => (
      <>
        <Text>
          No. Currently, slingshot is a webapp only. You can access it through a
          web browser like Chrome.
        </Text>
      </>
    ),
  },
  {
    id: 6,
    title: 'Is there a mobile app?',
    hiddenContent: () => (
      <>
        <Text>
          Currently, we do not have a mobile app, but we have designed the app
          to look nice on mobile screens.
        </Text>
      </>
    ),
  },
  // {
  //   id: 7,
  //   title: 'Is there an annual discount?',
  //   hiddenContent: () => (
  //     <>
  //       <Text>
  //         No, there is currently not a discount for annual subscriptions.
  //       </Text>
  //     </>
  //   ),
  // },
  // {
  //   id: 8,
  //   title: 'Is there a discount for many users?',
  //   hiddenContent: () => (
  //     <>
  //       <Text>
  //         How many users? If you have more than 100 users, please contact us!
  //       </Text>
  //     </>
  //   ),
  // },
  // {
  //   id: 9,
  //   title: 'How do we accept payment?',
  //   hiddenContent: () => (
  //     <>
  //       <Text>
  //         We accept payments through{' '}
  //         <S.Anchor href="https://stripe.com" target="_blank">
  //           Stripe
  //         </S.Anchor>
  //       </Text>
  //     </>
  //   ),
  // },
  // {
  //   id: 10,
  //   title: 'Why do we pay GNAR LLC?',
  //   hiddenContent: () => (
  //     <>
  //       <Text>
  //         Currently, as of March 21 2022, our bank is set up with our older
  //         company, GNAR LLC.
  //       </Text>
  //     </>
  //   ),
  // },
  {
    id: 11,
    title: 'Where is my data stored?',
    hiddenContent: () => (
      <>
        <Text>
          We host our application on AWS servers using{' '}
          <S.Anchor href="https://www.heroku.com/home" target="_blank">
            Heroku
          </S.Anchor>
        </Text>
      </>
    ),
  },
]

const MotionDiv = ({ children }) => {
  const showUpVariant = showUpMotion(0.5)

  return (
    <S.Content
      variants={showUpVariant}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
    >
      {children}
    </S.Content>
  )
}

const Faq = () => (
  <Container>
    <Observer section="faq">
      <SectionTitle>Frequently Asked Questions</SectionTitle>
      <S.ContentContainer>
        {_.chunk(summaries, 2).map((chunk, i) => (
          <MotionDiv key={i}>
            {chunk.map((summary) => (
              <S.Details key={summary.id}>
                <S.Summary>{summary.title}</S.Summary>
                <S.HiddenContent>{summary.hiddenContent()}</S.HiddenContent>
              </S.Details>
            ))}
          </MotionDiv>
        ))}
      </S.ContentContainer>
    </Observer>
  </Container>
)

export default Faq
