import Feature from '../Feature'

import excelCrane from '../../images/excel.svg'
import crane from '../../images/crane.svg'
import numbers from '../../images/numbers.svg'

const Operations = () => {
  const investmentDescription = () => (
    <>
      <ul>
        <li>50+ excel invoices to 2 elegant views</li>
        <li>Fewer steps = less human errors</li>
        <li>50 locations spend 1 minute versus 20 minutes</li>
        <li>
          <strong>Saving 190 HOURS per year!</strong>
        </li>
      </ul>
    </>
  )

  const customAppDescription = () => (
    <>
      <ul>
        <li>5 min in-app vs 1 hr on location in vehicle</li>
        <li>See who’s a top performer</li>
        <li>28% of vehicles are underutilized</li>
        <li>
          <strong>NOT buying 20 vehicles at $120k each SAVES $2.4M!</strong>
        </li>
      </ul>
    </>
  )

  const saveTimeDescription = () => (
    <>
      <ul>
        <li>2nd click for charts & full report</li>
        <li>Act on this info with team</li>
        <li>View key data for 40 locations & many departments</li>
        <li>
          <strong>Easily done in 10 SECONDS!</strong>
        </li>
      </ul>
    </>
  )

  const operationCollectionProps = [
    {
      key: 0,
      tag: 'Why did America’s largest rail operator give us $1.5M to help with their operations?',
      heading: 'Use less excel & admin time',
      description: investmentDescription(),
      image: excelCrane,
      imageAlt: 'excel pile',
      section: 'use less excel!',
    },
    {
      key: 1,
      tag: 'Why do they want to continue using their custom app?',
      heading: 'Better measurement reveals opportunity',
      description: customAppDescription(),
      image: crane,
      imageAlt: 'crane moving objects',
      section: 'better measurement reveals opportunity',
      reverse: true,
    },
    {
      key: 2,
      tag: 'How does RMS actually save time?',
      heading: 'Summaries by location with fast investigation',
      description: saveTimeDescription(),
      image: numbers,
      imageAlt: 'hyena calculating numbers',
      section: 'how rms save time',
    },
  ]

  return (
    <>
      {operationCollectionProps.map((props) => (
        <Feature key={props.key} {...props} />
      ))}
    </>
  )
}

export default Operations
