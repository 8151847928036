import styled from 'styled-components'
import { motion } from 'framer-motion'
import Text from '../Text'
import devices from '../../styles/devices'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 50px;
`

export const MotionIframeContainer = styled(motion.div)`
  aspect-ratio: 2 / 1.125;
  min-width: 65vw;

  @media screen and (max-width: 498px) {
    min-width: 90vw;
  }
`

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  width: 290px;
  margin-bottom: 50px;

  @media screen and (max-width: 498px) {
    margin-bottom: 0;
  }
`

export const Image = styled.img`
  object-fit: contain;
  height: 150px;
`

export const Name = styled(Text)`
  margin-top: 20px;
  font-size: 26px;
  font-weight: 600;
`

export const Role = styled(Text)`
  text-transform: uppercase;
  letter-spacing: 0.1em;
  text-align: center;
`

export const VideoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-top: 80px;
  width: 70%;
  margin: 0 auto;
`

export const Spacer = styled.div`
  margin: 150px 0;

  @media ${devices.mobile} {
    margin: 100px 0 50px;
  }
`

export const Extra = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  margin: 50px 0 100px;

  @medai ${devices.mobile} {
    margin: 50px 0 0;
  }
`
