import { motion } from 'framer-motion'
import Lottie from 'react-lottie'

import { Container } from '../../styles/globalStyles'

import { showUpMotion } from '../../config'

import CallToAction from '../CallToAction'
import Observer from '../Observer'
import SectionTitle from '../SectionTitle'
import Tag from '../Tag'

import * as S from './styles'

import Forced from '../../images/js/Forced'
import Visualized from '../../images/js/Visualized'
import Solution from '../../images/js/Solution'
import Focused from '../../images/js/Focused'

function renderSvgImage(image) {
  return {
    forced: <Forced />,
    visualized: <Visualized />,
    solution: <Solution />,
    focused: <Focused />,
  }[image]
}

const MotionDiv = ({ children }) => {
  const showUpVariant = showUpMotion(0.2)

  return (
    <motion.div
      variants={showUpVariant}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
    >
      {children}
    </motion.div>
  )
}

const Operation = ({
  reverse = false,
  hasCallToAction = false,
  heading,
  description,
  image,
  imageAlt,
  section,
  tag,
  bottomHeading,
  originalImageSize,
  lottieOptions,
  lottieConfig,
  svgImage,
  halfSizeImage,
}) => (
  <Container>
    <Observer section={section}>
      <S.Content>
        <S.Flex reverse={reverse}>
          <S.FlexChildren>
            <S.DescriptionContainer>
              {tag && (
                <MotionDiv>
                  <Tag icon={'✨'} description={tag} />
                </MotionDiv>
              )}
              <S.Heading as="div">
                <MotionDiv>{heading}</MotionDiv>
              </S.Heading>

              <S.Description as="div">
                <MotionDiv>{description}</MotionDiv>
              </S.Description>

              {hasCallToAction && (
                <S.CTAContainer>
                  <MotionDiv>
                    <CallToAction />
                  </MotionDiv>
                </S.CTAContainer>
              )}
            </S.DescriptionContainer>
          </S.FlexChildren>

          <S.FlexChildren>
            <S.AssetContainer>
              {svgImage && (
                <S.SvgImage halfsize={halfSizeImage}>
                  {renderSvgImage(svgImage)}
                </S.SvgImage>
              )}
              <MotionDiv>
                {image && (
                  <S.Image
                    src={image}
                    loading="lazy"
                    alt={imageAlt}
                    originalsize={originalImageSize}
                  />
                )}

                {lottieOptions && (
                  <Lottie
                    options={lottieOptions}
                    height={400}
                    width={400}
                    {...lottieConfig}
                  />
                )}
              </MotionDiv>
            </S.AssetContainer>
          </S.FlexChildren>
        </S.Flex>
      </S.Content>
    </Observer>
    {bottomHeading && (
      <S.BottomText>
        <br />
        <SectionTitle>{bottomHeading}</SectionTitle>
      </S.BottomText>
    )}
  </Container>
)

export default Operation
