import { Container } from '../../styles/globalStyles'

import Observer from '../Observer'
import SectionTitle from '../SectionTitle'
import Text from '../Text'

import * as S from './styles'

const sectionName = 'How does it work?'

const customDescription = () => (
  <>
    <Text>Example Workflow</Text>
    <br />
    <S.List>
      <S.ListItem color="red">To do</S.ListItem>
      <S.ListItem color="yellow">In Progress</S.ListItem>
      <S.ListItem color="yellow">Ready for review</S.ListItem>
      <S.ListItem color="green">Design approved</S.ListItem>
      <S.ListItem color="green">Ready for installation</S.ListItem>
    </S.List>
    <br />
    <Text>Your workflow status will be different by industry and sector.</Text>
  </>
)

const steps = [
  {
    id: 0,
    emoji: '✍️',
    title: 'Create an account',
    description: `$${process.env.REACT_APP_CURRENT_PRICE} reserves you a seat as one of our early adopters! If you’d like, we can work closely with you to ensure your alliance with slingshot grows in value.`,
  },
  {
    id: 1,
    emoji: '🚥',
    title: 'Share your workflow with us',
    description: customDescription(),
  },
  {
    id: 2,
    emoji: '💻',
    title: 'Add vendors and other businesses',
    description:
      'We’ll make your transition to slingshot easy by helping you create accounts and shared workspaces. We’ll even send invitations to your business partners! Got questions along the way? Lay ‘em on us.',
  },
  {
    id: 3,
    emoji: '👋',
    title: 'Say bye to extra calls, emails, and spreadsheets!',
    description:
      'With slingshot, syncing up is nearly obsolete because it gives businesses the omnipotence to see each other’s status concerning various tasks within all projects.',
  },
]

const HowItWorks = () => (
  <Container>
    <Observer section={sectionName}>
      <SectionTitle>{sectionName}</SectionTitle>

      <S.Content>
        {steps.map((step) => (
          <S.Step key={step.id}>
            <S.Emoji step={step.id + 1} />
            <S.StepContent>
              <S.Title>{step.title}</S.Title>
              <S.Description>{step.description}</S.Description>
            </S.StepContent>
          </S.Step>
        ))}
      </S.Content>
    </Observer>
  </Container>
)

export default HowItWorks
