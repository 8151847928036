import { createContext, useContext, useState } from 'react'
import mixpanel from 'mixpanel-browser'

const ThemeContext = createContext()

import { lightTheme, darkTheme } from '../styles/theme'

export function ThemeContextProvider({ children }) {
  const [theme, setTheme] = useState(lightTheme)

  function handleChangeTheme() {
    setTheme(theme === darkTheme ? lightTheme : darkTheme)

    if (process.env.NODE_ENV !== 'development') {
      mixpanel.track('theme_changed', {
        theme: theme.type,
      })
    }

    return null
  }

  const value = {
    theme,
    handleChangeTheme,
  }

  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
}

export function useTheme() {
  return useContext(ThemeContext)
}
