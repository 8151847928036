import { useEffect } from 'react'
import _ from 'lodash'
import { motion } from 'framer-motion'
import { useScrollSection } from 'react-scroll-section'

import * as S from './styles'

// import EmailForm from '../EmailForm'

import { Container, MotionSpan } from '../../styles/globalStyles'

import {
  headingSentence,
  headingLetter,
  showUpMotion,
  sectors,
  animatedWord,
} from '../../config'

import Arrow from '../../images/js/Arrow'

import { useLocation } from '../../context/locationContext'
import { useTheme } from '../../context/themeContext'

const sentenceThinWords = ['For', 'With', 'You', 'And', 'Your']

let emojiPositionCount = 0
let industryPositionCount = 0

function runIndustry({ elem, industries = [], emojis = [] }) {
  setInterval(() => {
    elem.innerHTML =
      industries[industryPositionCount] + ' ' + emojis[emojiPositionCount]
    industryPositionCount =
      industryPositionCount === industries.length - 1
        ? 0
        : (industryPositionCount += 1)
    emojiPositionCount =
      emojiPositionCount === emojis.length - 1 ? 0 : (emojiPositionCount += 1)
  }, 1200)
}

const Hero = () => {
  const {
    queryParams: { s },
  } = useLocation()

  const { theme } = useTheme()

  const calculatorSection = useScrollSection('calculator')

  const hasSector = !_.isNil(s) && !_.isEmpty(s)

  const initialIndustry = hasSector
    ? sectors[s].industries[0]
    : sectors['default'].industries[0]

  const headSentence = 'Streamline Task Collaboration with Clients And Your'

  const showUpVariant = showUpMotion()

  useEffect(() => {
    const emojis = hasSector ? sectors[s].emojis : sectors['default'].emojis

    const elemIndustry = document.querySelector('#industry')

    if (!_.isNil(elemIndustry)) {
      runIndustry({
        elem: elemIndustry,
        emojis,
        industries: hasSector
          ? sectors[s].industries
          : sectors['default'].industries,
      })
    }
  }, [hasSector, s])

  return (
    <Container>
      <S.Content>
        <S.StyledText as="h1">
          <motion.div
            variants={headingSentence}
            initial="hidden"
            animate="visible"
          >
            {headSentence.split(' ').map((word, index) => {
              const wordToAnimate =
                word + (index !== headSentence.length - 1 ? '\u00A0' : '')

              return (
                <MotionSpan key={`${word}-${index}`} variants={headingLetter}>
                  {sentenceThinWords.includes(word) ? (
                    wordToAnimate
                  ) : (
                    <strong>{wordToAnimate}</strong>
                  )}
                </MotionSpan>
              )
            })}
            <S.Industry
              id="industry"
              variants={animatedWord}
              initial="hidden"
              animate="visible"
            >
              <strong>{initialIndustry}</strong>
            </S.Industry>
          </motion.div>
        </S.StyledText>

        <S.Flex column>
          <motion.div
            variants={showUpVariant}
            initial="hidden"
            animate="visible"
          >
            <S.AsideText>
            Say goodbye to manual spreadsheets and never-ending email chains! 
            Align tasks effortlessly with your vendors and clients, 
            boost efficiency, and become their go-to superhero. 🦸‍♂️
            </S.AsideText>
          </motion.div>
        </S.Flex>

        <S.Flex>
          <motion.div
            variants={showUpVariant}
            initial="hidden"
            animate="visible"
          >
            {/* <EmailForm /> */}
          </motion.div>
        </S.Flex>

        <S.BottomTextContainer
          variants={showUpVariant}
          initial="hidden"
          animate="visible"
        >
          <S.IconContainer
            animate={{
              y: [0, 10, 0, 10, 0, 10, 0],
            }}
            transition={{
              duration: 2,
              ease: 'easeInOut',
              repeat: Infinity,
              repeatDelay: 1,
            }}
            onClick={calculatorSection.onClick}
          >
            <Arrow fill={theme.button} />
          </S.IconContainer>
        </S.BottomTextContainer>
      </S.Content>
    </Container>
  )
}

export default Hero
