import Lottie from 'react-lottie'

import * as S from './styles'

import { Container } from '../../styles/globalStyles'

import Observer from '../Observer'
import SectionTitle from '../SectionTitle'
import CallToAction from '../CallToAction'

import Forced from '../../images/js/Forced'
import Visualized from '../../images/js/Visualized'
import Solution from '../../images/js/Solution'
import Focused from '../../images/js/Focused'

function renderSvgImage(image) {
  return {
    forced: <Forced />,
    visualized: <Visualized />,
    solution: <Solution />,
    focused: <Focused />,
  }[image]
}

const AppPreview = ({
  title,
  description,
  image,
  showCallToAction,
  halfSizeImage,
  lottieOptions,
  lottieConfig,
  svgImage,
}) => (
  <Container>
    <Observer section={title}>
      <S.Content>
        {title && <SectionTitle>{title}</SectionTitle>}
        {image && <S.Image src={image} alt={title} halfsize={halfSizeImage} />}
        {svgImage && (
          <S.SvgImage halfsize={halfSizeImage}>
            {renderSvgImage(svgImage)}
          </S.SvgImage>
        )}
        {lottieOptions && (
          <Lottie
            options={lottieOptions}
            height={400}
            width={400}
            {...lottieConfig}
          />
        )}
        <S.DescriptionContainer>
          <S.StyledText>{description}</S.StyledText>
          {showCallToAction && <CallToAction />}
        </S.DescriptionContainer>
      </S.Content>
    </Observer>
  </Container>
)

export default AppPreview
