import { createContext, useContext, useState, useEffect } from 'react'
import mixpanel from 'mixpanel-browser'
import _ from 'lodash'

import { getUrlParam } from '../config'

const LocationContext = createContext()

const params = new URLSearchParams(window.location.search)

export function LocationContextProvider({ children }) {
  const [queryParams, setQueryParams] = useState(getUrlParam(params))

  const templateValue = queryParams.t
  const sectorValue = queryParams.s

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      mixpanel.track('template_requested', {
        template:
          !_.isNil(templateValue) && !_.isEmpty(templateValue)
            ? templateValue
            : 'default',
      })

      if (!_.isNil(sectorValue) && !_.isEmpty(sectorValue)) {
        mixpanel.track('sector_requested', { sector: sectorValue })
      }
    }
  }, [templateValue, sectorValue])

  const value = {
    queryParams,
    setQueryParams,
  }

  return (
    <LocationContext.Provider value={value}>
      {children}
    </LocationContext.Provider>
  )
}

export function useLocation() {
  return useContext(LocationContext)
}
