import Observer from '../Observer'
import SectionTitle from '../SectionTitle'
// import Text, { Anchor } from '../Text'
import CallToAction from '../CallToAction'

import * as S from './styles'

// const currentPrice = process.env.REACT_APP_CURRENT_PRICE

// const princingCards = [
//   {
//     Title: 'Free',
//     Description: [
//       '1 to 2 people',
//       'Max 5 projects',
//       'Unlimited external partners',
//       'Unlimited Tasks',
//       'Unlimited Status Updates',
//     ],
//     Action: (() => (
//       <CallToAction isBuyAction disabled>
//         Coming soon
//       </CallToAction>
//     ))(),
//   },
//   {
//     Title: 'Pre Order',
//     Price: currentPrice,
//     Description: [
//       `$${currentPrice} per person per month`,
//       'Unlimited projects per member',
//       'Unlimited external partners',
//       'Unlimited Tasks',
//       'Unlimited Status Updates',
//     ],
//     Bonus: (() => (
//       <Text>
//         Includes <strong>2 free months!</strong>
//         <br />
//         <S.Member>(regularly ${currentPrice}/mo)</S.Member>
//       </Text>
//     ))(),
//     Action: (() => <CallToAction isBuyAction />)(),
//   },
//   {
//     Title: 'Enterprise',
//     Description: [
//       'Want to add more than 15 users?',
//       'Contact us!',
//       (() => (
//         <Anchor href="mailto:contact@slingshot-inc.com">
//           contact@slingshot-inc.com
//         </Anchor>
//       ))(),
//     ],
//     Action: (() => (
//       <CallToAction onClick={handleClick} isBuyAction>
//         Contact Us
//       </CallToAction>
//     ))(),
//   },
// ]

const Pricing = () => (
  <Observer section="pricing">
    <S.Container>
      <SectionTitle>Free! Sign Up Now!</SectionTitle>
      <br />
      <br />
      <CallToAction isBuyAction />
      {/* <S.Flex>aaaaaa
        {princingCards.map((card, index) => (
          <S.Card key={index}>
            <S.CardTitle>{card.Title}</S.CardTitle>
            <Text>
              <S.List>
                {card.Description.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </S.List>
            </Text>
            {card.Price && (
              <S.CardPrice>
                <em>$</em>
                <strong>{card.Price}</strong>
                <S.Member>/ member</S.Member>
              </S.CardPrice>
            )}
            {card.Bonus}
            {card.Action}
          </S.Card>
        ))}
      </S.Flex> */}
    </S.Container>
  </Observer>
)

export default Pricing
