import * as S from './styles'

import Observer from '../Observer'
import SectionTitle from '../SectionTitle'
import Text from '../Text'
import Button from '../CallToAction'

import Arrow from '../../images/js/Arrow'
import GnarLogo from '../../images/gnar_logo.svg'

import { useTheme } from '../../context/themeContext'

import { teamList, showUpMotion } from '../../config'

function handleClick() {
  return window.open('https://gnar.io', '_blank')
}

const showUpVariant = showUpMotion()

const Team = () => {
  const { theme } = useTheme()

  return (
    <Observer section="team">
      <br />
      <br />
      <S.Container>
        <S.Flex row>
          <S.Icon src={GnarLogo} alt="Gnar Logo" />
          <SectionTitle inline>GNAR - The Team Behind Slingshot</SectionTitle>
        </S.Flex>
        <br />
        <br />
        <br />
        <br />
        <br />
        <S.TeamContainer>
          {teamList.map((teamMember, index) => (
            <S.Box key={index}>
              <S.Image src={teamMember.image} alt={teamMember.name} />
              <S.Name>{teamMember.name}</S.Name>
              <S.Role>{teamMember.role}</S.Role>
            </S.Box>
          ))}
        </S.TeamContainer>
        <br />
        <br />
        <S.Flex>
          <S.Center>
            <Text>We can do custom apps too!</Text>
            <Text>Need help with that? Check out our other projects!</Text>
          </S.Center>
          <Button onClick={handleClick}>Go to site</Button>
        </S.Flex>

        <S.BottomTextContainer
          variants={showUpVariant}
          initial="hidden"
          animate="visible"
        >
          <S.IconContainer
            animate={{
              y: [0, 10, 0, 10, 0, 10, 0],
            }}
            transition={{
              duration: 2,
              ease: 'easeInOut',
              repeat: Infinity,
              repeatDelay: 2,
            }}
          >
            <Arrow fill={theme.button} />
          </S.IconContainer>
          <S.BottomText>
            Why did America&apos;s largest rail operator invest{' '}
            <strong>$1.5M</strong> <br />
            in our team to help innovate tech in their operations?
          </S.BottomText>
          <S.IconContainer
            animate={{
              y: [0, 10, 0, 10, 0, 10, 0],
            }}
            transition={{
              duration: 2,
              ease: 'easeInOut',
              repeat: Infinity,
              repeatDelay: 4,
            }}
          >
            <Arrow fill={theme.button} />
          </S.IconContainer>
        </S.BottomTextContainer>
      </S.Container>
    </Observer>
  )
}

export default Team
