import CallToAction from '../CallToAction'
import Observer from '../Observer'

import * as S from './styles'

import Logo from '../../images/js/Logo'
import Twitter from '../../images/js/Twitter'
import Linkedin from '../../images/js/Linkedin'

import { useTheme } from '../../context/themeContext'

const Footer = () => {
  const { theme } = useTheme()

  return (
    <Observer section="footer">
      <S.Wrapper>
        <S.Container>
          <S.Content>
            <S.LogoWrapper>
              <Logo fill={theme.button} />
            </S.LogoWrapper>
            <S.StyledText>
              <S.StyledLink to="/credits">Meet the creators</S.StyledLink>
            </S.StyledText>
            <S.Anchor href="mailto:contact@slingshot-inc.com">
              contact@slingshot-inc.com
            </S.Anchor>
            <S.StyledText>I want to try slingshot!</S.StyledText>
            <br />
            <CallToAction isBuyAction />
            <br />
            <br />
            <br />
            <br />

            <S.StyledText small>
              {`© ${new Date().toLocaleDateString('en-us', {
                year: 'numeric',
              })} SLINGSHOT INC`}
            </S.StyledText>
            <S.StyledText small mono>
              All icons are from the noun project.
            </S.StyledText>
          </S.Content>
          <S.Content>
            <S.SocialWrapper>
              <a
                href="https://www.linkedin.com/company/gnar1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <S.SocialIcon>
                  <Linkedin fill={theme.button} />
                </S.SocialIcon>
              </a>
              <a
                href="https://twitter.com/gnariot"
                target="_blank"
                rel="noopener noreferrer"
              >
                <S.SocialIcon>
                  <Twitter fill={theme.button} />
                </S.SocialIcon>
              </a>
              <a href="https://slingshot-inc.com" rel="noopener noreferrer">
                <S.SocialIcon>
                  <S.StyledText>slingshot-inc.com</S.StyledText>
                </S.SocialIcon>
              </a>
            </S.SocialWrapper>
            <S.Policy
              href="https://www.iubenda.com/privacy-policy/48454390/legal"
              rel="noopener noreferrer"
              target="_blank"
            >
              <S.SocialIcon>
                <S.StyledText>Privacy</S.StyledText>
              </S.SocialIcon>
            </S.Policy>
            <S.Policy
              href="https://www.iubenda.com/privacy-policy/48454390/cookie-policy"
              rel="noopener noreferrer"
              target="_blank"
            >
              <S.SocialIcon>
                <S.StyledText>Cookie Policy</S.StyledText>
              </S.SocialIcon>
            </S.Policy>
            <S.Policy
              href="https://www.iubenda.com/terms-and-conditions/48454390"
              rel="noopener noreferrer"
              target="_blank"
            >
              <S.SocialIcon>
                <S.StyledText>Terms and Conditions</S.StyledText>
              </S.SocialIcon>
            </S.Policy>
            <S.StyledText>
              1190B Shafter Ave <br /> San Francisco, CA 94124
            </S.StyledText>
          </S.Content>
        </S.Container>
      </S.Wrapper>
    </Observer>
  )
}

export default Footer
