import previewHeroku from '../../images/heroku.png'
import previewCodeish from '../../images/codeish.png'

import SectionTitle from '../SectionTitle'
import Observer from '../Observer'

import { Container } from '../../styles/globalStyles'

import * as S from './styles'

const pressInformation = [
  {
    title: 'Heroku Article',
    description: 'slingshot (RMS project) uses Heroku for faster development',
    image: previewHeroku,
    href: 'https://www.heroku.com/customers/gnar',
  },
  {
    title: 'Code(ish) Podcast',
    description: 'slingshot (RMS project) uses IoT to monitor rail yards',
    image: previewCodeish,
    href: 'https://www.heroku.com/podcasts/codeish/88-monitoring-productivity-through-iot',
  },
  {
    title: 'Heroku Article',
    description: 'slingshot (RMS project) is transforming heavy industries',
    image: previewHeroku,
    href: 'https://blog.heroku.com/transform-a-heavy-industry',
  },
]

const Press = () => (
  <Container>
    <S.Content>
      <Observer section="press">
        <SectionTitle>Press</SectionTitle>
        <S.InnerContainer>
          {pressInformation.map((details) => (
            <S.ClickableBox
              href={details.href}
              target="_blank"
              key={details.href}
            >
              <S.Image src={details.image} />
              <S.Title>{details.title}</S.Title>
              <S.Description>{details.description}</S.Description>
            </S.ClickableBox>
          ))}
        </S.InnerContainer>
      </Observer>
    </S.Content>
  </Container>
)

export default Press
