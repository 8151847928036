export default function Twitter({ fill }) {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.9564 1.92363L15.8538 1.69297C15.9577 1.66626 16.0404 1.78523 15.979 1.87749C15.6885 2.31453 15.0886 3.17889 14.7107 3.41198C14.1982 3.72762 15.2445 10.8999 7.87354 13.4833C7.87354 13.4833 4.0074 14.9789 0.0704028 12.8811C-0.0405983 12.8204 -0.0146184 12.648 0.108191 12.6238C1.04343 12.4465 3.44767 11.9464 4.02157 11.3855C4.11368 11.2957 4.08297 11.1378 3.96725 11.082C3.43822 10.8343 1.87004 10.0161 1.29142 8.78997C1.25599 8.7147 1.31267 8.62972 1.39297 8.62972C1.56065 8.62972 1.82517 8.62972 2.05898 8.62972C2.16289 8.62972 2.19832 8.4889 2.10857 8.43791C1.49689 8.09557 0.443559 7.18507 0.162514 4.92462C0.153067 4.85663 0.216833 4.80079 0.2806 4.81779C0.389239 4.84935 0.554558 4.89791 0.736411 4.95375C0.847412 4.98774 0.934794 4.85663 0.863943 4.75951C0.457726 4.21322 -0.137427 2.96038 0.493154 0.763049C0.519133 0.675641 0.62777 0.648933 0.686813 0.716917C1.29141 1.40404 3.90821 4.12581 7.58069 4.35647C7.58069 4.35647 7.33271 0.595517 10.5517 0.0443646C11.1186 -0.0527548 11.7019 0.0128009 12.2451 0.209468C12.7292 0.384283 13.2984 0.656217 13.5299 1.03498L15.3602 0.408563C15.4948 0.362431 15.6106 0.515394 15.535 0.639221L14.843 1.76823C14.7981 1.84593 14.8666 1.94791 14.9564 1.92363Z"
        fill={fill || 'white'}
      />
    </svg>
  )
}
