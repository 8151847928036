import styled from 'styled-components'
import Text from '../Text'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.inline ? 'initial' : '100%')};
`

export const Title = styled(Text)`
  font-size: 2.5rem;
  cursor: default;
  font-weight: 600;
`
