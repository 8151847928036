import styled from 'styled-components'
import { motion } from 'framer-motion'

import devices from '../../styles/devices'

import Text from '../Text'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 50px;
  margin-top: 120px;
`

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  width: 290px;
  margin-bottom: 50px;

  @media screen and (max-width: 498px) {
    margin-bottom: 0;
  }
`

export const Image = styled.img`
  object-fit: contain;
  height: 150px;
`

export const Name = styled(Text)`
  margin-top: 20px;
  font-size: 26px;
  font-weight: 600;
`

export const Role = styled(Text)`
  text-transform: uppercase;
  letter-spacing: 0.1em;
  text-align: center;
`

export const TeamContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  width: 70%;
  margin: 0 auto 100px;
`

export const Flex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;

  ${(props) =>
    props.row &&
    `
    flex-direction: row;

    @media ${devices.mobile} {
      flex-direction: column;
    text-align: center;
    }
  `}
`

export const Center = styled.div`
  text-align: center;
  font-size: 18px;
`

export const BottomTextContainer = styled(motion.div)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 150px;
`

export const BottomText = styled(Text)`
  max-width: 520px;
  text-align: center;
  font-size: var(--default-font-size);

  @media ${devices.mobile} {
    flex-direction: column;
    width: 100%;
  }
`

export const IconContainer = styled(motion.div)`
  height: 25px;

  svg {
    height: 100%;
  }
`

export const Icon = styled.img`
  width: 35px;
`
