import * as S from './styles'
import mixpanel from 'mixpanel-browser'

import { useCalendly } from '../../context/calendlyContext'

function handleClick({ showCalendly, setShowCalendly, isBuyAction }) {
  if (process.env.NODE_ENV !== 'development') {
    mixpanel.track(isBuyAction ? 'try_now_requested' : 'calendly_requested')
  }

  return isBuyAction
    ? window.open(process.env.REACT_APP_STRIPE_BUYLINK, '_blank')
    : setShowCalendly(!showCalendly)
}

const Button = ({
  children,
  onClick,
  small,
  smallest,
  isBuyAction,
  ...props
}) => {
  const { showCalendly, setShowCalendly } = useCalendly()

  if (isBuyAction) {
    return (
      <S.Button
        small={small}
        smallest={smallest}
        onClick={
          onClick ||
          (() => handleClick({ showCalendly, setShowCalendly, isBuyAction }))
        }
        {...props}
      >
        {children || 'Try now'}
      </S.Button>
    )
  }

  return (
    <S.Button
      small={small}
      onClick={
        onClick || (() => handleClick({ showCalendly, setShowCalendly }))
      }
      {...props}
    >
      {children || 'Schedule a call'}
    </S.Button>
  )
}

export default Button
