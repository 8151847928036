import styled from 'styled-components'
import { motion } from 'framer-motion'

import devices from '../../styles/devices'

export const ContentContainer = styled.div`
  display: flex;
  gap: 15px;

  @media ${devices.mobile} {
    flex-direction: column;
  }
`

export const Content = styled(motion.div)`
  display: grid;
  margin: 100px 0 150px;
  gap: 15px;
  width: 100%;
  align-self: start;

  @media ${devices.mobile} {
    :not(:last-child) {
      margin: 0;
    }

    :first-child {
      margin: 100px 0 0;
    }

    :last-child {
      margin: 0 0 100px;
    }
  }
`

export const Details = styled.details`
  padding: 20px;
  width: 100%;
  border-radius: var(--border-radius);
  background: ${(props) => props.theme.accent};
  font-size: 18px;

  @media ${devices.mobile} {
    width: 100%;
  }

  &[open] > summary:before {
    transform: rotate(90deg);
  }
`

export const Summary = styled.summary`
  font-weight: 600;
  cursor: pointer;
  position: relative;

  :before {
    content: '';
    border-width: 0.4rem;
    border-style: solid;
    border-color: transparent transparent transparent #fff;
    position: absolute;
    top: 4px;
    left: -2px;
    transform: rotate(0);
    transform-origin: 0.2rem 60%;
    transition: 0.25s transform ease;
  }

  ::marker {
    color: ${(props) => props.theme.accent};
  }
`

export const HiddenContent = styled.div`
  margin-top: 20px;
  line-height: 1.5;
  font-weight: 300;
`

export const Anchor = styled.a`
  color: ${(props) => props.theme.text};
  text-decoration: none;
  position: relative;
  display: inline-block;
  font-weight: 600;
  z-index: 1;

  :after {
    content: '';
    display: block;
    height: 15px;
    width: 100%;
    background: red;
    position: absolute;
    bottom: 0px;
    left: 0;
    z-index: -1;
    transition: 0.2s ease-in-out;
    background: ${(props) => props.theme.accent};
  }

  :hover {
    :after {
      background: ${(props) => props.theme.background};
    }
  }
`
