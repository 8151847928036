import _ from 'lodash'
import { useLocation } from '../context/locationContext'

const useParseUrl = (url) => {
  const { sector } = useLocation()

  return _.isNil(sector) ? url : url + `?sector=${sector}`
}

export default useParseUrl
