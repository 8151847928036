import styled, { css } from 'styled-components'

const shared = css`
  width: 100%;
  padding: 25px 10px;
  border: none;
  border-radius: var(--border-radius);
  border: 1px solid ${(props) => props.theme.text};
`

export const Container = styled.div`
  width: 50%;
  height: 100vh;
  display: grid;
  margin: 0 auto;
  place-items: center;
  align-content: center;
`

export const Content = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 300px;
  width: 80%;
`

export const Input = styled.input`
  ${shared}
`

export const Select = styled.select`
  ${shared}
`

export const Button = styled.button`
  border: 2px solid ${(props) => props.theme.button};
  color: ${(props) => props.theme.button};
  background: none;
  text-transform: uppercase;
  font-weight: 600;
  transition: all 0.2s ease;
  cursor: pointer;

  padding: 15px 35px;
  font-size: 1rem;

  ${(props) =>
    props.small &&
    `
    padding: 10px 35px;
    font-size: .8rem;
  `}

  :hover {
    color: ${(props) => props.theme.hoverAccentColor};
    background: ${(props) => props.theme.hoverAccent};
  }
`

export const Url = styled.p`
  margin-top: 50px;
  font-size: 1rem;
  text-align: center;
  position: relative;
  cursor: pointer;
  opacity: 0.6;
  transition: 0.2s all ease;

  :hover {
    opacity: 1;
  }
`
