export default function Linkedin({ fill }) {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5565 0H2.44347C1.09251 0 0 1.07716 0 2.39496V12.605C0 13.9343 1.09251 15 2.44347 15H13.5565C14.9075 15 16 13.9343 16 12.605V2.39496C16 1.07716 14.9075 0 13.5565 0ZM4.72247 12.9374H2.47871V6.21085H4.72247V12.9374ZM3.59471 5.38579C2.85463 5.38579 2.40822 4.90451 2.40822 4.32009C2.40822 3.72422 2.86637 3.25439 3.60646 3.25439C4.3583 3.25439 4.79295 3.72422 4.8047 4.32009C4.8047 4.90451 4.3583 5.38579 3.59471 5.38579ZM13.5918 12.9374H11.348V9.37357C11.348 8.411 10.9604 7.79221 10.1145 7.79221C9.43319 7.79221 9.05727 8.26203 8.89281 8.65164C8.84581 8.77769 8.83407 8.9725 8.83407 9.13293V12.9374H6.57856V8.39954C6.57856 7.52865 6.55507 6.80672 6.53157 6.21085H8.4699L8.57562 7.19633H8.62261C8.88106 6.79526 9.6094 6.05042 10.9134 6.05042C12.3465 6.05042 13.5918 6.93277 13.5918 9.07563V12.9374Z"
        fill={fill || 'white'}
      />
    </svg>
  )
}
