import { Section } from 'react-scroll-section'

import { video, video2, video3, video4, problemAndSolution, reminders, bonus } from './constants'

import {
  Hero,
  Contact,
  Calendly,
  Pricing,
  Calculator,
  Faq,
  AppPreviewContainer,
  ProjectPreview,
} from '../../components'

import { useCalendly } from '../../context/calendlyContext'

const Landing = () => {
  const { showCalendly } = useCalendly()

  return (
    <>
      <Hero />
      <AppPreviewContainer list={video} />
      <Section id="calculator">
        <Calculator />
      </Section>
      <AppPreviewContainer list={problemAndSolution} />
      <AppPreviewContainer list={reminders} />
      <ProjectPreview />
      <AppPreviewContainer list={bonus} />
      <Pricing />
      <Faq />
      <AppPreviewContainer list={video2} />
      <AppPreviewContainer list={video3} />
      <AppPreviewContainer list={video4} />
      <Contact />
      {showCalendly && <Calendly />}
    </>
  )
}

export default Landing
