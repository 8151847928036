import { useEffect, useState } from 'react'

import { useViewportScroll } from 'framer-motion'

import * as S from './styles'

import Logo from '../../images/js/Logo'
import CallToAction from '../CallToAction'
import { Link } from '../Text'

import { useTheme } from '../../context/themeContext'

import { useParseUrl } from '../../hooks'

const Header = () => {
  const [scrollYPosition, setScrollYPosition] = useState(0)

  const { theme, handleChangeTheme } = useTheme()

  const { scrollYProgress } = useViewportScroll()

  const variants = {
    initial: { backgroundColor: theme.background },
    moving: { backgroundColor: 'transparent', boxShadow: theme.boxShadow },
  }

  useEffect(() => {
    return scrollYProgress.onChange((position) => setScrollYPosition(position))
  }, [scrollYProgress])

  return (
    <S.MotionContainer
      initial="initial"
      animate={scrollYPosition > 0.01 ? 'moving' : 'initial'}
      variants={variants}
    >
      <S.Content>
        <Link to={useParseUrl('/')}>
          <Logo fill={theme.button} />
        </Link>

        <div>
          <S.Menu>
            <Link to="/credits">Team</Link>
            <CallToAction small isBuyAction />
          </S.Menu>
          <S.MobileMenu>
            <CallToAction smallest isBuyAction />
          </S.MobileMenu>
          <S.ThemeToggle
            sun={theme.type === 'light'}
            onClick={handleChangeTheme}
          />
        </div>
      </S.Content>
    </S.MotionContainer>
  )
}

export default Header
