import styled from 'styled-components'
import { motion } from 'framer-motion'

import { maxWidth } from '../../config'
import devices from '../../styles/devices'

const size = '2rem'
const gap = '55px'

export const MotionContainer = styled(motion.nav)`
  width: 100%;
  max-width: ${maxWidth};
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: var(--border-radius);
  height: 70px;
  background-color: ${(props) => props.theme.background} !important;
  display: grid;
  align-items: center;
  z-index: 9;

  @media screen and (max-width: 1290px) {
    margin-bottom: 0;
    max-width: 100%;
    height: 60px;
    border-radius: 0;
    top: 0;
  }
`

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 33px;
  width: 100%;
  align-items: center;

  div {
    display: flex;
    align-items: center;
    gap: ${gap};
  }

  a {
    transform: scale(0.9);
  }

  @media ${devices.mobile} {
    padding: 0 15px;

    a {
      transform: scale(0.7);
      margin-left: -25px;
    }
  }
`

export const Menu = styled.menu`
  display: flex;
  align-items: center;
  gap: ${gap};

  @media ${devices.mobile} {
    display: none;
  }
`

export const MobileMenu = styled.menu`
  display: none;

  @media ${devices.mobile} {
    display: flex;
    align-items: center;
    gap: ${gap};
  }
`

export const ThemeToggle = styled.span`
  appearance: none;
  outline: none;
  cursor: pointer;
  z-index: 1;

  width: ${size};
  height: ${size};
  box-shadow: inset calc(${size} * 0.33) calc(${size} * -0.25) 0;
  border-radius: 999px;
  color: hsl(240, 100%, 95%);

  transition: all 200ms;

  @media ${devices.mobile} {
    transform: scale(0.7);
  }

  ${(props) =>
    props.sun &&
    `
    --ray-size: calc(${size} * -0.4);
    --offset-orthogonal: calc(${size} * 0.65);
    --offset-diagonal: calc(${size} * 0.45);

    transform: scale(0.75);
    color: var(--slingshot-aqua);
    box-shadow: inset 0 0 0 ${size},
    calc(var(--offset-orthogonal) * -1) 0 0 var(--ray-size),
    var(--offset-orthogonal) 0 0 var(--ray-size),
    0 calc(var(--offset-orthogonal) * -1) 0 var(--ray-size),
    0 var(--offset-orthogonal) 0 var(--ray-size),
    calc(var(--offset-diagonal) * -1) calc(var(--offset-diagonal) * -1) 0
      var(--ray-size),
    var(--offset-diagonal) var(--offset-diagonal) 0 var(--ray-size),
    calc(var(--offset-diagonal) * -1) var(--offset-diagonal) 0 var(--ray-size),
    var(--offset-diagonal) calc(var(--offset-diagonal) * -1) 0 var(--ray-size);
  `}
`
