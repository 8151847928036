import styled from 'styled-components'

export const Button = styled.button`
  border: 2px solid ${(props) => props.theme.button};
  color: ${(props) => props.theme.button};
  background: none;
  text-transform: uppercase;
  font-weight: 600;
  transition: all 0.2s ease;
  cursor: pointer;
  z-index: 1;

  padding: 15px 35px;
  font-size: 1rem;

  ${(props) =>
    props.small &&
    `
    padding: 10px 35px;
    font-size: .8rem;
  `}

  ${(props) =>
    props.smallest &&
    `
    padding: 6px;
    white-space: nowrap;
    font-size: .8rem;
    margin-right: -35px;
    border: 5px solid ${(props) => props.theme.button};
  `}

  ${(props) =>
    props.disabled &&
    `
    pointer-events: none;
    opacity: .4;
  `}

  :hover {
    color: ${(props) => props.theme.hoverAccentColor};
    background: ${(props) => props.theme.hoverAccent};
  }
`
