import Feature from '../Feature'

// import statusPreview from '../../images/lottie/app_preview_status.json'
// import colorsPreview from '../../images/lottie/app_preview_colors.json'
// import { getLottieOptions } from '../../config'

const Operations = () => {
  const operationCollectionProps = [
    {
      key: 0,
      heading: `Laser-Focused Project Updates 🔍`,
      description: (() => (
        <>
          Get a crystal-clear{' '}
          <strong>
            overview of project updates for each vendor relationship 
            and receive alerts for tasks that scream,
          </strong>{' '}
          &quot;Hey, I need your attention, like, now!&quot; 📣
        </>
      ))(),
      imageAlt: 'App Preview for Status',
      section: 'focused project updates',
      originalImageSize: true,
      halfSizeImage: true,
      svgImage: 'focused',
    },
    {
      key: 1,
      heading: `Visualize Status & Priorities in Full Color 🌈`,
      description: (() => (
        <>
          Customize colors to match your team&apos;s vibe, 
          making status and priority adjustments a breeze. {' '}
          <strong>
            Keep stakeholders in the loop with automatic notifications about any changes. 📬
          </strong>
          .
        </>
      ))(),
      imageAlt: 'App Preview for Colors',
      section: 'visualized status & priority',
      reverse: true,
      // originalImageSize: true,
      halfSizeImage: true,
      svgImage: 'visualized',
    },
  ]

  return (
    <>
      {operationCollectionProps.map((props) => (
        <Feature key={props.key} {...props} />
      ))}
    </>
  )
}

export default Operations
