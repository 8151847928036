import styled from 'styled-components'
import devices from '../../styles/devices'

import Text from '../Text'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 80px;
  align-items: center;
`

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin-bottom: 100px;
  align-items: center;
`

export const StyledText = styled(Text)`
  font-weight: 300;
  font-size: var(--default-font-size);
  text-align: left;
  line-height: 1.5;
  width: 60%;
  text-align: left;

  @media ${devices.mobile} {
    width: 100%;
  }
`

export const Image = styled.img`
  ${(props) =>
    props.halfsize &&
    `
  width: 50%;
`}
  @media ${devices.mobile} {
    width: 100%;

    div[aria-label='animation'] {
      width: 100% !important;
      height: 100% !important;
    }
  }
`

export const SvgImage = styled.div`
  ${(props) =>
    props.halfsize &&
    `
  width: 50%;
`}
  @media ${devices.mobile} {
    width: 100%;
  }
`
