import * as S from './styles'

import Observer from '../Observer'

import { Container } from '../../styles/globalStyles'

const Quotes = () => (
  <Container>
    <Observer section="quotes">
      <S.Content>
        <S.QuoteContainer>
          <S.Quote>
            <S.Description>
              &quot;The simple interface led to a{' '}
              <strong>YOY increase of +200%</strong> more audits recorded. And,
              we can see where we need to focus at a glance.&quot;
            </S.Description>
            <S.Author>
              — <strong>Steve Shaffer</strong> <br /> <i>AVP Safety</i> <br />{' '}
              RMS Intermodal
            </S.Author>
          </S.Quote>
        </S.QuoteContainer>
      </S.Content>
    </Observer>
  </Container>
)

export default Quotes
