import * as S from './styles'
import { InlineWidget } from 'react-calendly'

import { useCalendly } from '../../context/calendlyContext'

const Calendly = () => {
  const { showCalendly, setShowCalendly } = useCalendly()

  return (
    <S.Container onClick={() => setShowCalendly(!showCalendly)}>
      <InlineWidget url="https://calendly.com/gnar" />
    </S.Container>
  )
}

export default Calendly
