import Feature from '../Feature'

import animationData from '../../images/contact_spaceship.svg'

const description = () => (
  <>
    Streamline external <strong>project coordination</strong>!
  </>
)

const featureProps = {
  heading: 'Contact us',
  description: description(),
  section: 'contact us',
  hasCallToAction: true,
  reverse: true,
  originalImageSize: true,
  image: animationData,
}

const Contact = () => <Feature {...featureProps} />

export default Contact
