import styled from 'styled-components'
import Text from '../Text'

export const Tag = styled.div`
  border: 2px solid ${(props) => props.theme.tag};
  width: fit-content;
  width: 100%;
  border-radius: var(--border-radius);
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 15px;
`

export const TagDescription = styled(Text)`
  font-size: 16px;
`

export const Icon = styled.div`
  font-size: 28px;
`
