import { motion } from 'framer-motion'
import styled, { createGlobalStyle } from 'styled-components'

import { maxWidth } from '../config'
import devices from './devices'

export default createGlobalStyle`
    * {
        box-sizing: border-box;
        margin: 0;
        outline: 0;
    }
    html {
      scroll-behavior: smooth;
    }
    body {
        background: ${(props) => props.theme.background};
        color: ${(props) => props.theme.text};    
    }
    p, a, span {
      line-height: 1.5;
    }

    p::selection,
    h1::selection,
    h2::selection,
    h3::selection,
    h4::selection,
    span::selection,
    a::selection,
    strong::selection,
    ul::selection,
    li::selection,
    em::selection,
    b::selection,
    br::selection,
    button::selection,
    summary::selection,
    details::selection,
    #emoji::selection,
    #industry::selection {
      background: ${(props) => props.theme.hoverAccent};
      color: ${(props) => props.theme.hoverAccentColor};
    }

    img {
      user-select: none;
    }

    strong {
      font-weight: 600;
    }

    ul {
      line-height: 1.8;
      list-style: circle;
      padding-left: 20px;
    }
`

export const Container = styled.div`
  margin: 0 auto;
  max-width: ${maxWidth};

  @media ${devices.mobile} {
    max-width: 100%;
    padding: 0 20px;
  }
`

export const MotionSpan = styled(motion.span)`
  display: inline-block;
`
