import ProblemPreview from '../../images/lottie/app_preview_problem.json'
import ExtraFeaturesImg from '../../images/extra_features.png'

import { getLottieOptions } from '../../config'
import { VideoPreview } from '../../components'

export const problemAndSolution = [
  {
    title: (() => (
      <>
        The Dreaded Project Coordination
        <br />
        Monster 👹
      </>
    ))(),
    description: (() => (
      <>
        You&apos;re drowning in Excel sheets, clients, and a{' '}
        <strong>
          flood of calls and emails just to stay afloat with project statuses,
        </strong>{' '}
        all while deadlines chase you like a relentless T-Rex. 🦖
      </>
    ))(),
    lottieOptions: getLottieOptions(ProblemPreview),
    lottieConfig: {
      width: window.innerWidth < 770 ? '100%' : 600,
      height: window.innerWidth < 770 ? '100%' : 600,
    },
    showCallToAction: false,
    halfSizeImage: true,
  },
  {
    title: 'Meet Your Secret Weapon: slingshot 🎯',
    description: (() => (
      <>
        <strong>
          Bring all your to-dos and project requirements together in one
          super-centralized platform. Collaborate seamlessly with stakeholders,
        </strong>{' '}
        deliver projects faster than a speeding bullet, and gain x-ray vision
        into project statuses. 💨
      </>
    ))(),
    showCallToAction: true,
    halfSizeImage: true,
    svgImage: 'solution',
  },
]

export const reminders = [
  {
    title: 'Collaboration Sans Compulsory Sign-Ups 🎉',
    description: (() => (
      <>
        <strong>Collaborators not using slingshot?</strong>
        <br />
        <br />
        <p>
          No worries! 🤗 <strong>Assign tasks that send email reminders</strong>
          , when they click to respond, the status{' '}
          <strong>update gets zapped right back to slingshot</strong>, Keep
          everything organized while
          <strong>
            {' '}
            ensuring collaborators stay on track and crush deadlines. ⏰
          </strong>
          .
        </p>
      </>
    ))(),
    showCallToAction: false,
    halfSizeImage: true,
    svgImage: 'forced',
  },
]

export const video = [
  {
    description: (
      <VideoPreview embedLink="https://www.youtube.com/embed/x2bTm2kmfxs?controls=1" />
    ),
    halfSizeImage: true,
  },
]

export const video2 = [
  {
    title: '1min App Walkthough 🎬',
    description: (
      <VideoPreview embedLink="https://www.youtube.com/embed/ULcwD6dOKeQ?controls=1" />
    ),
    halfSizeImage: true,
  },
]

export const video3 = [
  {
    title: 'Inviting Your Guest 🎉',
    description: (
      <VideoPreview embedLink="https://www.youtube.com/embed/PJf4om7KunY?controls=1" />
    ),
    halfSizeImage: true,
  },
]

export const video4 = [
  {
    title: 'More App Details 🤩',
    description: (
      <VideoPreview embedLink="https://www.youtube.com/embed/47t9GFOnpeE?controls=1" />
    ),
    halfSizeImage: true,
  },
]

export const bonus = [
  {
    title: 'Turbocharge Customization 🏎️💨',
    description: (() => (
      <>
        <div>
          <img
            src={ExtraFeaturesImg}
            alt="Extra features"
            style={{ width: '100%', height: 'auto' }}
          />
        </div>
        <br />
        <strong>
          Turn Slingshot into your personal task-conquering machine
        </strong>
        <br />
        <br />
        <p>
          Custom tags 🏷️, tailored workflows, and personalized fields. Sort
          tasks like a pro by priority, due date, creation date, or last updated
          date.{' '}
          <strong>
            Stay on top of the game with a chronological view of recent
            activity,
          </strong>
          <strong> so you never miss a beat. 🥁</strong>.
        </p>
      </>
    ))(),
    showCallToAction: false,
    // halfSizeImage: true,
  },
]
