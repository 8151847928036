import styled from 'styled-components'
import { Link } from '@reach/router'

import Text from '../Text'
import { maxWidth } from '../../config'
import devices from '../../styles/devices'

export const Wrapper = styled.div`
  background-color: ${(props) => props.theme.accent};
`

export const Container = styled.div`
  height: fit-content;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0 auto;
  padding: 30px 100px 50px;
  max-width: ${maxWidth};

  @media (max-width: 490px) {
    height: fit-content;
    padding: 50px 0;
    width: 100%;
    flex-direction: column-reverse;
  }

  @media ${devices.mobile} {
    padding: 20px;
  }

  a {
    text-decoration: none;
  }
`

export const StyledText = styled(Text)`
  font-size: 16px;
  line-height: 1.6;
  margin: 10px 0;
  text-decoration: none;

  ${(props) => props.small && `font-size: 12px;`}

  ${(props) =>
    props.mono &&
    `
    margin: 0;
    font-size: 8px;
    letter-spacing: 2px;
  `}
`

export const SocialWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 60px;
  margin-left: -20px;
`

export const SocialIcon = styled.div`
  width: 20px;
  margin: 0 15px;
  object-fit: contain;
  transition: 0.2s all ease;
  white-space: nowrap;

  :hover {
    transform: translateY(-3px);
  }
`

export const Anchor = styled.a`
  text-decoration: none;
  transition: 0.2s all;
  margin: 10px 0;
  color: ${(props) => props.theme.text};
`

export const LogoWrapper = styled.div`
  margin: none;
  margin-bottom: 60px;
`

export const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;

  :first-of-type {
    width: 30%;

    @media ${devices.mobile} {
      width: 50%;
    }

    @media (max-width: 490px) {
      width: 90%;
      margin: 0 auto;
    }
  }

  :last-of-type {
    width: 30%;

    @media ${devices.mobile} {
      width: 50%;
    }

    @media (max-width: 490px) {
      width: 90%;
      margin: 0 auto 50px;
    }
  }
`

export const Logo = styled.img`
  margin: none !important;
  height: 42px;
  width: 120px;
  object-fit: contain;
`

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${(props) => props.theme.text};
`

export const Policy = styled.a`
  width: fit-content;

  > div {
    margin: 0;
    width: 100%;
  }
`
