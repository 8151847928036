export const darkTheme = {
  type: 'dark',
  background: 'var(--background)',
  tag: 'var(--slingshot-white-95)',
  accent: 'var(--accent)',
  text: 'var(--slingshot-white)',
  button: 'var(--slingshot-white)',
  hoverAccent: 'var(--slingshot-aqua)',
  hoverAccentColor: 'var(--slingshot-white)',
  lineAccent: 'var(--slingshot-aqua)',
  boxShadow:
    'rgb(0 0 0) 0px 0px 2px, rgb(0 0 0 / 52%) 0px 4px 12px, rgb(88 115 147) 0px 0px 0px 0.5px inset',
  calculatorBackground: 'var(--accent)',
  calculatorAccent: 'var(--background)',
  calculatorThumb: 'var(--background)',
  calculatorThumbBorder: 'var(--slingshot-white)',
  ghostBackground: 'var(--background)',
  formButton: 'var(--slingshot-aqua)',
  formButtonColor: 'var(--slingshot-white)',
}

export const lightTheme = {
  type: 'light',
  background: 'var(--slingshot-white)',
  text: 'var(--slingshot-aqua-20)',
  tag: 'var(--slingshot-white-95)',
  accent: 'var(--slingshot-white-95)',
  button: 'var(--slingshot-aqua)',
  hoverAccent: 'var(--slingshot-aqua-20)',
  hoverAccentColor: 'var(--slingshot-white)',
  lineAccent: 'var(--slingshot-white-95)',
  boxShadow:
    'rgb(155 155 155) 0px 0px 2px, rgb(182 182 182 / 62%) 0px 4px 12px, rgb(88 115 147) 0px 0px 0px 0.5px inset',
  calculatorBackground: 'var(--slingshot-white-95)',
  calculatorAccent: 'var(--slingshot-white)',
  calculatorThumb: 'var(--slingshot-white)',
  calculatorThumbBorder: 'var(--background)',
  ghostBackground: 'var(--background)',
  formButton: 'var(--slingshot-aqua)',
  formButtonColor: 'var(--slingshot-white)',
}
