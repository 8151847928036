import styled from 'styled-components/macro'
import { Anchor } from '../Text'
import devices from '../../styles/devices'

export const Content = styled.section`
  height: 100%;
  padding-bottom: 100px;
`

export const InnerContainer = styled.div`
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;

  @media ${devices.mobile} {
    margin-top: 50px;
  }
`

export const ClickableBox = styled(Anchor)`
  width: 25%;
  transition: 0.2s all ease;

  @media ${devices.mobile} {
    width: 100%;
    margin: 0 0 60px;
  }
`

export const Image = styled.img`
  width: 100%;
`

export const Title = styled.span`
  display: block;
  font-size: 20px;
  margin: 15px 0;
  font-weight: 600;

  @media ${devices.mobile} {
    white-space: nowrap;
  }
`

export const Description = styled.span`
  display: block;
  font-size: 16px;
`
