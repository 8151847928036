import { useState } from 'react'
import _ from 'lodash'
import { encode } from 'base-64'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import * as S from './styles'

import { queryStringValues, templates } from '../../config'

const codeToName = {
  s: `"sector"`,
}

const baseUrl =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3000'
    : 'https://slingshot-inc.com'

const Builder = () => {
  const [url, setUrl] = useState(null)
  const parsedUrl = `${baseUrl}${url ? `/?q=${url}` : ''}`

  function handleSubmit(event) {
    event.preventDefault()

    const parsedData = []
    const data = new FormData(event.target)

    if (!_.isNil(data.get('template')) && !_.isEmpty(data.get('template'))) {
      parsedData.push(`t=${data.get('template')}`)
      parsedData.push(`s=${data.get('s')}`)
    } else {
      queryStringValues.map((value) => {
        const inputValue = data.get(value)

        if (!_.isEmpty(inputValue)) {
          parsedData.push(`${value}=${inputValue}`)
        }
      })
    }

    console.log('parsedData', parsedData)

    setUrl(encode(parsedData.join('&').toLocaleLowerCase()))
    event.target.reset()
  }

  return (
    <S.Container>
      <S.Content onSubmit={(e) => handleSubmit(e)}>
        {queryStringValues.map((query, index) => (
          <S.Input
            key={index}
            name={query}
            placeholder={`Enter value for ${codeToName[query]} word within the main heading field`}
          />
        ))}
        <br />
        OR
        <br />
        <br />
        <S.Select name="template">
          <option value="" defaultValue hidden>
            Choose a template here
          </option>
          {_.keys(templates).map((template, index) => (
            <option key={index} value={template}>
              {template}
            </option>
          ))}
        </S.Select>
        <S.Button>generate link</S.Button>
      </S.Content>

      <CopyToClipboard text={parsedUrl}>
        <S.Url title="click to copy">{parsedUrl}</S.Url>
      </CopyToClipboard>
    </S.Container>
  )
}

export default Builder
