import styled, { css } from 'styled-components'
import { Link } from '@reach/router'

const shared = css`
  margin: 0;
  color: ${(props) => props.theme.text};
`

export const Text = styled.p`
  ${shared}
`

export const Anchor = styled.a`
  ${shared}
  text-decoration: none;
`

export const StyledLink = styled(Link)`
  ${shared}
  display: grid;
  place-items: center;
  text-decoration: none;
`
