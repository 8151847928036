import React from 'react'
import ReactDOM from 'react-dom'
import mixpanel from 'mixpanel-browser'
import { ScrollingProvider } from 'react-scroll-section'

import { ThemeContextProvider } from './context/themeContext'
import { LocationContextProvider } from './context/locationContext'
import { CalendlyContextProvider } from './context/calendlyContext'

import Routes from './routes'

import './styles/colors.css'
import './styles/fonts.css'
import './styles/variables.css'

ReactDOM.render(
  <React.StrictMode>
    <ThemeContextProvider>
      <LocationContextProvider>
        <CalendlyContextProvider>
          <ScrollingProvider offset={-120}>
            <Routes />
          </ScrollingProvider>
        </CalendlyContextProvider>
      </LocationContextProvider>
    </ThemeContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN)
